export const forecastHeader = [
  {
    "field": "decision",
    "headerText": "",
    "width": "50",
    "decisionAttr": "product"
  },
  {
    "field": "product",
    "headerText": "Style",
    "allowFiltering": true,
    "width": "180",
    "filter": {
      "type": "Menu"
    }
  },
  {
    "field": "category",
    "headerText": "Category",
    "allowFiltering": true,
    "width": "180",
    "filter": { "type": "Menu" }
  },
  {
    "field": "overall_ros",
    "headerText": "ROS - Current Demand",
    "width": "120",
  },
  {
    "field": "margin",
    "headerText": "Net Margin",
    "width": "120",
  },
  {
    "field": "return_percent",
    "headerText": "Return %",
    "width": "120",
  },
  {
    "field": "seasonality",
    "headerText": "Seasonality",
    "width": "120",
  },
  {
    "field": "summer_ratio",
    "headerText": "Sales(%) in summer",
    "width": "120",
  }
]
