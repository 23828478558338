import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { FilterBayService } from "src/app/services/filter-bay.service";
import { ReturnAnalyzerService } from "src/app/services/return-analyzer.service";
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";

@Component({
  selector: "app-filter-molecule",
  templateUrl: "./filter-molecule.component.html",
  styleUrls: ["./filter-molecule.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FilterMoleculeComponent implements OnInit, OnChanges {
  filterData: FormGroup;
  @Output() filterOutput = new EventEmitter<{}>();
  @Output() addFiltersOut = new EventEmitter<any>();
  @Output() closeFiltersOutput = new EventEmitter<boolean>();
  @Input() isDateFilter: boolean;
  @Input() monthFieldRequired: boolean;
  @Input() returnsDateRange;
  @Input() detectChangesFromParent;
  selectedOptions: { [key: string]: string[] } = {};
  allFilters: {}[];
  endDate = new Date();
  isLoading = true;
  filterDataList;
  addFilters: any;
  selectedMonthsControl = new FormControl();
  public datePipe = new DatePipe("en-US");
  dateRangeForm = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    frequency: new FormControl(null),
  });

  filtersLength: number;
  lastSixMonths: String[];
  months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  monthYearRange: { startDate: string; endDate: string };
  removeMonthRange: boolean;
  constructor(
    private filterbayservice: FilterBayService,
    private returnService: ReturnAnalyzerService,
    private clickStream: ClickStreamAnalyticsService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.filterbayservice.getFiltersFromParent.subscribe((data) => {
      this.closeFilter(data);
    });
  }
  ngOnInit(): void {
    if (this.returnsDateRange) {
      const dateRange = `${this.returnsDateRange.startDate} - ${this.returnsDateRange.endDate}`;
      this.addFilters = {
        DateRange: dateRange,
      };
      this.monthYearRange = {
        startDate: this.returnsDateRange.startDate,
        endDate: this.returnsDateRange.endDate,
      };
      this.addFiltersOut.emit(this.addFilters);
      this.filtersLength = Object.keys(this.addFilters).length;
    }

    this.lastSixMonths = this.getLastSixMonths();
    this.filterbayservice.getFilters().subscribe(
      (res) => {
        if (res) {
          this.filterDataList = res["filterDataList"];
          const keys: [] = this.filterDataList.map((item) => item.code);
          this.returnService.setKeys(keys);
          const group: any = {};
          this.filterDataList.filter((key) => {
            const key_name = key["code"];
            if (key["condition"] === "OR") {
              group[key_name] = new FormControl();
            } else {
              group[key_name] = new FormControl();
            }
          });
          this.filterData = new FormGroup(group);
          this.isLoading = false;
        }
      },
      (error) => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  closeFilters() {
    this.closeFiltersOutput.emit(true);
  }
  applyFilters() {
    const sendPayloadFilters: any = {};
    this.addFilters = {};
    if (
      this.monthFieldRequired &&
      this.monthYearRange.startDate &&
      this.monthYearRange.endDate
    ) {
      const startDateOut = this.datePipe.transform(
        this.monthYearRange.startDate,
        "yyyy-MM-dd"
      );
      const endDateOut = this.datePipe.transform(
        this.monthYearRange.endDate,
        "yyyy-MM-dd"
      );
      const dateRange = `${startDateOut} - ${endDateOut}`;
      sendPayloadFilters.startDate = startDateOut;
      sendPayloadFilters.endDate = endDateOut;
      this.addFilters.DateRange = dateRange;
    }
    const startDate =
      this.dateRangeForm.get("startDate").value != null
        ? this.datePipe.transform(
            new Date(this.dateRangeForm.get("startDate").value),
            "yyyy-MM-dd"
          )
        : null;
    const endDate =
      this.dateRangeForm.get("endDate").value != null
        ? this.datePipe.transform(
            new Date(this.dateRangeForm.get("endDate").value),
            "yyyy-MM-dd"
          )
        : null;
    let filters = {};
    for (let key in this.filterData.value) {
      if (this.filterData.value[key]) {
        filters[key] = this.filterData.value[key];
      }
    }
    sendPayloadFilters.filters = {};
    for (let option in this.selectedOptions) {
      if (this.selectedOptions[option].length > 0)
        sendPayloadFilters.filters[option] = this.selectedOptions[option];
    }
    if (Object.keys(sendPayloadFilters.filters).length > 0) {
      this.addFilters.filters = sendPayloadFilters.filters;
    }
    if (startDate != null) {
      sendPayloadFilters.startDate = startDate;
    }
    if (endDate != null) {
      sendPayloadFilters.endDate = endDate;
      sendPayloadFilters.frequency = this.dateRangeForm.get("frequency").value;
    }
    if (startDate != null && endDate == null) {
      this.addFilters.DateRange = `from ${startDate}`;
    } else if (startDate == null && endDate != null) {
      this.addFilters.DateRange = `till ${endDate}`;
    } else if (startDate != null && endDate != null) {
      this.addFilters.DateRange = `${startDate} - ${endDate}`;
      if (this.dateRangeForm.get("frequency").value)
        this.addFilters.Frequency = this.dateRangeForm.get("frequency").value;
    }
    this.filtersLength = Object.keys(this.addFilters).length;
    this.addFiltersOut.emit(this.addFilters);
    this.filterOutput.emit(sendPayloadFilters);
    this.clickStream.publishGAEvent(EventType.FILTER, {
      section: "Business Insights",
    });
  }

  captialize(input) {
    const caps = input[0].toUpperCase() + input.slice(1);
    return caps.replace(/_/g, " ");
  }

  toggleSelectAll(filterCode: string) {
    const allOptions = this.filterData
      .get(filterCode)
      .value.filter((v) => v !== 0);
    const allOptionValues =
      this.filterDataList.find((filter) => filter.code === filterCode)
        ?.values || [];
    if (allOptions.length === allOptionValues.length) {
      this.filterData.get(filterCode).setValue([]);
    } else {
      this.filterData.get(filterCode).setValue([...allOptionValues, 0]);
    }
    this.selectedOptions[filterCode] = this.filterData
      .get(filterCode)
      .value.filter((v) => v != 0);
  }

  selectSingle(filterCode: string, variable: any) {
    // Get the current selected values for the given filterCode
    const currentValues = this.filterData.value[filterCode] || [];

    // If the variable is already in the selected values, remove it (unselect)
    if (currentValues.includes(variable)) {
      // Remove the variable from the selection
      const updatedValues = currentValues.filter((v) => v !== variable);
      this.filterData.get(filterCode).setValue(updatedValues);
    } else {
      // Otherwise, add the variable to the selection
      const updatedValues = [...currentValues, variable];
      this.filterData.get(filterCode).setValue(updatedValues);
    }

    // Filter out the '0' value (used for "Select All")
    const getFilterValues = this.filterData.value[filterCode].filter(
      (ignoreZero) => ignoreZero !== 0
    );

    // Get all available option values from the filterDataList
    const allOptionValues =
      this.filterDataList.find((filter) => filter.code === filterCode)
        ?.values || [];

    // If all options are selected, add '0' to indicate "Select All"
    if (getFilterValues.length === allOptionValues.length) {
      this.filterData.get(filterCode).setValue([...getFilterValues, 0]);
    } else {
      // Otherwise, remove '0' if it's present (to unselect "Select All")
      this.filterData.get(filterCode).setValue(getFilterValues);
    }

    // Update selected options for this filter code, excluding '0'
    this.selectedOptions[filterCode] = getFilterValues;

    // Log the selected options for debugging
    console.log(this.selectedOptions, "selectedsingle");
  }
  clearFilter(filterCode: string): void {
    // Clear the selected options for the given filter code
    this.filterData.get(filterCode).setValue([]);
    this.selectedOptions[filterCode] = [];
  }

  closeFilter(i) {
    if (i === "DateRange") {
      // this.selectedMonthsControl.value &&
      //   this.selectedMonthsControl.patchValue(null);
      if (this.monthYearRange.startDate && this.monthYearRange.endDate)
        delete this.addFilters.startDate;
      delete this.addFilters.endDate;
      delete this.monthYearRange.startDate;
      delete this.monthYearRange.endDate;
      this.removeMonthRange = true;

      const dateRange = ["startDate", "endDate"];
      dateRange.filter((date) => {
        const control = this.dateRangeForm.get(date);
        control.patchValue(null);
      });
      for (const key in this.addFilters) {
        if (key === i) {
          delete this.addFilters[key];
        }
      }
    } else {
      const control = this.filterData.get(i);
      control.patchValue(null);
      for (const key in this.addFilters.filters) {
        if (key === i) {
          delete this.addFilters.filters[key];
          delete this.selectedOptions[key];
        }
      }
    }
    this.filtersLength = Object.keys(this.addFilters).length;
    this.applyFilters();
  }
  getLastSixMonths = (): string[] => {
    const currentDate = new Date();
    const lastSixMonths: string[] = [];

    for (let i = 0; i < 6; i++) {
      const currentMonth = (currentDate.getMonth() - i + 12) % 12;
      lastSixMonths.push(this.months[currentMonth]);
    }

    return lastSixMonths.reverse();
  };

  monthDateRange(event: { startDate: string; endDate: string }) {
    this.monthYearRange = {
      startDate: event.startDate,
      endDate: event.endDate,
    };
  }

  mapFilterLabels(code) {
    switch (code) {
      case "category_level1":
        return "Product Category";
      case "category_level3":
        return "Revenue Category";
      default:
        return code;
    }
  }
}
