<div class="mat-elevation-z4 overlay-filters">
  <div class="filters d-flex" *ngIf="isLoading">
    <div class="filter-header">
      <span class="title">FILTERS</span>
      <span mat-icon-button class="close-icon" (click)="closeFilters()">
        <mat-icon>close</mat-icon>
      </span>
    </div>
    <div class="filter-body">
    <ng-container *ngFor="let number of [0, 1, 2, 3, 4]">
      <div class="holder-card mat-elevation-z4">
        <div style="font-weight: lighter; width: 100%; height: 2em" class="flowsmart-placeholder"></div>
        <div style="font-weight: lighter; width: 100%; height: 2em" class="flowsmart-placeholder"></div>
        <div style="font-weight: lighter; width: 100%; height: 2em" class="flowsmart-placeholder"></div>
        <div style="font-weight: lighter; width: 100%; height: 1em" class="flowsmart-placeholder"></div>
      </div>
    </ng-container>
    </div>
  </div>
  <div class="filters" *ngIf="!isLoading">
    <div class="filter-header">
      <span class="title">FILTERS</span>
      <span mat-icon-button class="close-icon" (click)="closeFilters()">
        <mat-icon>close</mat-icon>
      </span>
    </div>
    <div class="filter-body">
      <ng-container *ngIf="isDateFilter" [formGroup]="dateRangeForm">
        <mat-form-field>
          <mat-label>Enter a date range</mat-label>
          <div style="display: flex; justify-content: space-between'">
            <mat-date-range-input [max]="endDate" [rangePicker]="picker">
              <input formControlName="startDate" matStartDate placeholder="Start date" />
              <input formControlName="endDate" matEndDate placeholder="End date" />
            </mat-date-range-input>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-datepicker-toggle style="width:1.5rem;margin:-10px 0" matIconSuffix
              [for]="picker"></mat-datepicker-toggle>
          </div>
          <!-- <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint> -->
        </mat-form-field>
        <!-- <mat-form-field>
        <mat-label>Frequency</mat-label>
        <mat-select #matSelect formControlName="frequency">
          <mat-option value="DAY">DAY</mat-option>
          <mat-option value="WEEK">WEEK</mat-option>
          <mat-option value="MONTH">MONTH</mat-option>
        </mat-select>
      </mat-form-field> -->
      </ng-container>
      <!-- <mat-form-field [formGroup]="filterData" *ngFor="let filter of filterDataList">
      <mat-label>{{ mapFilterLabels(filter.code) | capitalize }}</mat-label>
      <mat-select #matSelect [formControlName]="filter.code" [multiple]="filter.condition == 'OR' ? true : false"
        panelWidth="auto">
        <mat-icon class="close-button" (click)="matSelect.close()" color="primary">close</mat-icon>
        <mat-option (click)="toggleSelectAll(filter.code)" [value]="0">All</mat-option>
        <mat-option *ngFor="let item of filter.values" value="{{ item }}" (click)="selectSingle(filter.code)">{{ item
          }}</mat-option>
      </mat-select>
    </mat-form-field> -->
  <ng-container *ngIf="monthFieldRequired">
    <app-date-month-range-molecule [removeMonthRange]="removeMonthRange" (DateMonthRange)="monthDateRange($event)"
      [returnsDateRange]="returnsDateRange"></app-date-month-range-molecule>
  </ng-container>
      <div [formGroup]="filterData" *ngFor="let filter of filterDataList" class="filter-container">
        <div class="filter-label">{{ mapFilterLabels(filter.code) | capitalize }}
          <button *ngIf="selectedOptions[filter.code]?.length > 0" class="clear-button"
            (click)="clearFilter(filter.code)">
            Clear
          </button>
        </div>
        <div class="filter-options">
          <label *ngFor="let item of filter.values" class="filter-option">
            <input type="checkbox" [checked]="selectedOptions[filter.code]?.includes(item)"
              (change)="selectSingle(filter.code , item)" [value]='item' class="filter-checkbox" />
            {{ item }}
          </label>
        </div>
      </div>
    </div>
    <div class="filter-footer">
      <button *ngIf="!isLoading" (click)="closeFilters()" class="mat-button mat-secondary mat-raised-button">
        <mat-icon aria-hidden="false" aria-label="Close Filter Options" fontIcon="close-icon"></mat-icon>
        Close
      </button>
      <button *ngIf="!isLoading" (click)="applyFilters()" class="mat-button mat-primary mat-raised-button">
        <mat-icon aria-hidden="false" aria-label="Apply Filters" fontIcon="filter_alt"></mat-icon>
        Apply
      </button>
    </div>
  </div>

</div>