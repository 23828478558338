<div class="re-ordering-main-container">
    <app-header-content-help-text [Heading]="reportNameWithSpace"></app-header-content-help-text>
    <div class="buttons-container">
        <button mat-button mat-raised-button color="primary" (click)="generateReOrdering()"
            [disabled]="!isGenerateEnabled" [class.no-clicks]="loadingState.generate">
            <!-- Generate -->
            <span *ngIf="!loadingState.generate">Generate</span>
            <mat-spinner *ngIf="loadingState.generate" diameter="20" class="spinner"></mat-spinner>
            <span *ngIf="loadingState.generate">In Progress...</span>
        </button>
        <input #fileInput type="file" hidden (change)="onFileSelected($event)">
        <button mat-button mat-raised-button color="primary" (click)="triggerFileInput(fileInput)"
            [disabled]="!isUploadEnabled" [class.no-clicks]="loadingState.upload">
            <mat-icon matTooltip="Upload File">file_upload</mat-icon>
            <span *ngIf="!loadingState.upload"> Upload</span>
            <mat-spinner *ngIf="loadingState.upload" diameter="20" class="spinner"></mat-spinner>
            <span *ngIf="loadingState.upload">In Progress...</span>
        </button>
        <button mat-button mat-raised-button color="primary" (click)="reCompute()" [disabled]="!isReComputeEnabled"
            [class.no-clicks]="loadingState.recompute">
            <span *ngIf="!loadingState.recompute"> Re-Compute</span>
            <mat-spinner *ngIf="loadingState.recompute" diameter="20" class="spinner"></mat-spinner>
            <span *ngIf="loadingState.recompute">In Progress...</span>
        </button>
    </div>
    <div class="tabs-container">
        <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedIndexChange)="onTabChange($event)"
            animationDuration="300ms">
            <mat-tab label="Re-Ordering" [class.mat-elevation-z8]="selectedTabIndex == 0">
                <div *ngIf="selectedTabIndex == 0" class="grid-container">
                    <div *ngIf="!tableDataFetched" style="display:flex;justify-content: space-between;">
                        <ng-container *ngFor="let number of [0, 1, 2,4,5]">
                            <div style="width: 20%;margin-top:20px">
                                <p style="font-style: normal;text-align: center;width: 80%;
                            height: 10em;margin: 0px auto;" class="flowsmart-placeholder"></p>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="tableDataFetched" class="text-right fs-margin-0-1"><span>Reports are generated as on
                            <strong>{{reOderingGeneratedDate | date}}</strong></span></div>
                    <app-syncfusion-grid [reportName]="reportNameWithSpace" [dynamic_headers]="dynamic_headers"
                        [data]="data" [freezeColumns]="2" [readOnlyMode]="true"
                        *ngIf="tableDataFetched && dynamic_headers?.length>0"></app-syncfusion-grid>
                </div>
            </mat-tab>
            <mat-tab label="Summary" [class.mat-elevation-z8]="selectedTabIndex == 0">
                <div *ngIf="selectedTabIndex == 1" class="grid-container">
                    <div *ngIf="!summaryTableFetched" style="display:flex;justify-content: space-between;">
                        <ng-container *ngFor="let number of [0, 1, 2,4,5]">
                            <div style="width: 20%;margin-top:20px">
                                <p style="font-style: normal;text-align: center;width: 80%;
                            height: 10em;margin: 0px auto;" class="flowsmart-placeholder"></p>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="summaryTableFetched" class="text-right fs-margin-0-1"><span>Reports are generated as on
                            <strong>{{summarygGeneratedDate | date}}</strong></span></div>
                    <app-syncfusion-grid [reportName]="reportNameWithSpace" [dynamic_headers]="summaryDynamicHeaders"
                        [data]="summaryData" [freezeColumns]="2"
                        *ngIf="summaryTableFetched && summaryDynamicHeaders?.length>0"></app-syncfusion-grid>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>