import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { SizeRatioConverterService } from 'src/app/services/size-ratio-converter.service';


@Component({
  selector: "app-variant-ratio-trend-molecule",
  templateUrl: "./variant-ratio-molecule.component.html",
  styleUrls: ["./variant-ratio-molecule.component.scss"],
  providers: [],
})
export class VariantRatioMoleculeComponent implements OnInit {
  
  @Input() styleCode;
  gridData:any;
  dynamic_headers:any;
  isLoading: boolean = true;
  constructor(
    private ratioConverterService: SizeRatioConverterService,
  ) {

  }
  ngOnInit(): void {
    this.ratioConverterService.getVariantRatioData({code: this.styleCode}).subscribe(
      (response) => {
        if (response) {
          this.gridData = response['items'] || [];
          this.dynamic_headers = JSON.parse(response['headerContent']);
          this.isLoading = false;
        }
      },
      (error) => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}
