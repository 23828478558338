<div class="chatbot-header" @chatbotAnimation>
  <h1 class="styled-header"><strong>HAWK</strong></h1>
  <span class="tagline" appTypingEffect="Data's Sharpest Insight, Delivered Instantly" [typingSpeed]="80"></span>
  <!--<button class="close-btn" (click)="closeChatbot()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>-->
  <div class="dropdown-container">
    <span class="credit_limit">
      <svg style="margin-right: 2px;" xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 -960 960 960"
        width="14px" fill="#ffa000">
        <path
          d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80ZM253-253l227-227v-320q-134 0-227 93t-93 227q0 64 24 123t69 104Z" />
      </svg>
      <!-- <svg style="margin-left: 2px;" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFA000"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm491-80h69v-69l-69 69Zm-457 0h73l120-120h85L392-200h64l120-120h85L541-200h65l120-120h34v-440H200v509l69-69h85L234-200Zm72-200-56-56 177-177 80 80 147-147 56 56-203 204-80-80-121 120ZM200-200v-560 560Z"/></svg> -->
      Tokens
    </span>
    <div class="dropdown-content">
      <p>Total Tokens: {{ formatIndianNumber(credit_limit) }}</p>
      <!-- <p>Available Limit: {{available_limit}}</p> -->
      <p>Unused Tokens: {{this.available_percent}}%</p>
    </div>
  </div>
</div>

<div class="chatbot-container" @chatbotAnimation>
  <!-- <app-new-home-page-template></app-new-home-page-template> -->
  <div class="new-chatbot-container" #chatWindow>
    <ng-container *ngIf="summarise">
      <app-dashboard-summary></app-dashboard-summary>
    </ng-container>

    <div class="chat-window" #chatWindow>
      <ng-container *ngIf="!summarise">
        <div *ngIf="showSamplePrompt">
          <div class="icon-sample"><svg version="1.1" style=" padding:2px;" id="Icons"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"
              xml:space="preserve" width="30px" height="30px" fill="#EC9600" stroke="#EC9600">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <style type="text/css">
                  .st0 {
                    fill: none;
                    stroke: #EC9600;
                    stroke-width: 2;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 10;
                  }
                </style>
                <g>
                  <path class="st0"
                    d="M17.9,9.9c-4.6,0.9-6,2.3-6.9,6.9c-0.9-4.6-2.3-6-6.9-6.9C8.7,9,10.1,7.6,11,3C11.9,7.6,13.3,9,17.9,9.9z">
                  </path>
                </g>
                <g>
                  <path class="st0"
                    d="M21.8,25c-3.2,0.6-4.1,1.6-4.8,4.8c-0.6-3.2-1.6-4.1-4.8-4.8c3.2-0.6,4.1-1.6,4.8-4.8 C17.6,23.4,18.6,24.4,21.8,25z">
                  </path>
                </g>
                <g>
                  <path class="st0"
                    d="M29,15c-2.6,0.5-3.4,1.3-3.9,3.9c-0.5-2.6-1.3-3.4-3.9-3.9c2.6-0.5,3.4-1.3,3.9-3.9C25.6,13.7,26.4,14.5,29,15 z">
                  </path>
                </g>
                <line class="st0" x1="5" y1="23" x2="5" y2="23"></line>
                <line class="st0" x1="28" y1="6" x2="28" y2="6"></line>
              </g>
            </svg></div>
          <div class="sample_prompt">
            <span class="sample_prompt_text"
              (click)="sendMessage('Top selling categories by revenue in last 30 days')">Top selling categories by
              revenue in last 30 days</span>
            <span class="sample_prompt_text" (click)="sendMessage('Products with less than 60 days of stock')">Products
              with less than 60 days of stock</span>
            <span class="sample_prompt_text" (click)="sendMessage('Top 100 products by lost sales qty')">Top 100
              products by lost sales qty</span>
          </div>
        </div>
      </ng-container>

      <div *ngFor="let message of messages" class="chat-message">
        <div
          [ngClass]="{'user-message': message.sender === 'user', 'bot-message': message.sender === 'bot', 'dark-theme' : true}">
          <!-- Text Message -->
          <div *ngIf="message.text" class="chat-text">
            <span class="chatbot_botIcon" *ngIf="message.sender === 'bot'">
              <svg version="1.1" style="border: 1px solid #d3d3d3b3;border-radius:50%; padding:2px;" id="Icons"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"
                xml:space="preserve" width="20px" height="20px" fill="#EC9600" stroke="#EC9600">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <style type="text/css">
                    .st0 {
                      fill: none;
                      stroke: #EC9600;
                      stroke-width: 2;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-miterlimit: 10;
                    }
                  </style>
                  <g>
                    <path class="st0"
                      d="M17.9,9.9c-4.6,0.9-6,2.3-6.9,6.9c-0.9-4.6-2.3-6-6.9-6.9C8.7,9,10.1,7.6,11,3C11.9,7.6,13.3,9,17.9,9.9z">
                    </path>
                  </g>
                  <g>
                    <path class="st0"
                      d="M21.8,25c-3.2,0.6-4.1,1.6-4.8,4.8c-0.6-3.2-1.6-4.1-4.8-4.8c3.2-0.6,4.1-1.6,4.8-4.8 C17.6,23.4,18.6,24.4,21.8,25z">
                    </path>
                  </g>
                  <g>
                    <path class="st0"
                      d="M29,15c-2.6,0.5-3.4,1.3-3.9,3.9c-0.5-2.6-1.3-3.4-3.9-3.9c2.6-0.5,3.4-1.3,3.9-3.9C25.6,13.7,26.4,14.5,29,15 z">
                    </path>
                  </g>
                  <line class="st0" x1="5" y1="23" x2="5" y2="23"></line>
                  <line class="st0" x1="28" y1="6" x2="28" y2="6"></line>
                </g>
              </svg>
            </span>
            <span>{{ message.text }}</span>
            <span *ngIf="message.sender === 'user'" class="chatbot_botIcon">{{ userId }} </span>
          </div>

          <!-- Syncfusion Grid Table -->
          <ng-container *ngIf="message.tableData">
            <ejs-grid #grid style="margin-top: 10px;" [dataSource]="message.tableData" height="auto" width="auto"
              [allowPaging]='true' [allowFiltering]='true' [filterSettings]="filterSettings" [allowSorting]='true'
              [allowExcelExport]="true" [allowPaging]="true" [id]="message.tableId"
              (toolbarClick)="toolbarClick($event)" [toolbar]="['ExcelExport']" allowTextWrap='true'
              [textWrapSettings]='wrapSettings'>
              <e-columns>
                <ng-container *ngFor="let header of message.tableHeaders">
                  <e-column *ngIf="header.type == 'text'" [field]="header.field" [headerText]="header.headerText"
                    [allowFiltering]="header.allowFiltering" [filter]="header.filter">
                    <ng-template #template let-data>
                      <span>{{ data[header.field] }}</span>
                    </ng-template>
                  </e-column>
                  <e-column *ngIf="header.type == 'image'" [field]="header.field" [headerText]="header.headerText"
                    [allowFiltering]="false">
                    <ng-template #template let-data>
                      <img [src]="data[header.field]" style="max-height: 75px;object-fit: contain;">
                    </ng-template>
                  </e-column>
                </ng-container>
                <!-- Add more columns as needed -->
              </e-columns>
            </ejs-grid>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="scroll-to-bottom-arrow" *ngIf="showArrow" (click)="scrollToBottom1()">
    <mat-icon>arrow_downward</mat-icon>
  </div>
  <div class="chat-input" [ngClass]="{ 'input-focused': isInputFocused }">
    <input #inputField [(ngModel)]="userInput" placeholder="Ask HAWK..." (keyup.enter)="sendMessage()"
      (focus)="onInputFocus()" (blur)="onInputBlur()" />
    <button (click)="sendMessage()" [disabled]="isButtonDisabled()" mat-icon-button>
      <span *ngIf="loading" class="spinner"></span>
      <mat-icon *ngIf="!loading">arrow_upward</mat-icon>
    </button>
  </div>
</div>