import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { gridHeaders } from "src/app/properties/returns-analyser-grid";
import { ReturnAnalyzerService } from "src/app/services/return-analyzer.service";
import { dateProcessing } from "../../../properties/tableConfig";
import { FilterBayService } from "src/app/services/filter-bay.service";

@Component({
  selector: "app-returns-analyzer-organisms",
  templateUrl: "./returns-analyzer-organisms.component.html",
  styleUrls: ["./returns-analyzer-organisms.component.scss"],
})
export class ReturnsAnalyzerOrganismsComponent implements OnInit {
  visualizationData: any;
  gridData: any;
  dynamicHeaders: any;
  gridDataProcessing: any;
  dynamicHeadersProcessing: any;
  scoreCardData: any;
  scoreCardResponse: boolean;
  chartJSResponse: boolean;
  prossingResponse: boolean;
  GridHighestReturnsResponse: boolean;
  filtersEvent: any;
  public datePipe = new DatePipe("en-US");
  pivotFields: string[] = [];
  selectedPivot: string;
  returnDateTableData: any;
  dataSource = new MatTableDataSource<any>();
  displayedColumns = dateProcessing;
  showOverlay;
  changesDetected: boolean;
  addFilters: any;
  detectChanges: boolean;

  constructor(
    private returnService: ReturnAnalyzerService,
    private filterBayService: FilterBayService
  ) {}
  ngOnInit(): void {
    this.returnService.groupByKeys.subscribe((keys: string[]) => {
      this.pivotFields.push(...keys);
      this.selectedPivot = this.pivotFields[0].toString();
      this.returnService
        .getVisualChart({
          ...this.filtersEvent,
          ...{ groupBy: this.selectedPivot },
        })
        .subscribe(
          (res) => {
            if (res) {
              res["chartJSResponseData"].displayName = null;
              this.visualizationData = res["chartJSResponseData"];
              this.visualizationData.options = {
                //indexAxis: 'y', used to change to horizontal bar
                locale: "en-IN",
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: `${this.selectedPivot}`,
                      font: {
                        weight: "bold",
                      },
                    },
                    type: "category",
                  },
                  y: {
                    type: "linear",
                    title: {
                      display: true,
                      text: "Sold/Returned Qty",
                      font: {
                        weight: "bold",
                      },
                    },
                    display: true,
                    grid: {
                      display: true,
                      lineWidth: 0.5,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              };
            }
          },
          (error) => {},
          () => {
            this.chartJSResponse = true;
          }
        );
    });
    const { startDate, endDate } = this.getLastMonthRange();
    const startDateOut = this.datePipe.transform(startDate, "yyyy-MM-dd");
    const endtDateOut = this.datePipe.transform(endDate, "yyyy-MM-dd");
    this.filtersEvent = {
      startDate: startDateOut,
      endDate: endtDateOut,
    };
    this.returnService.getHighestReturns(this.filtersEvent).subscribe(
      (res) => {
        if (res) {
          this.gridData = res["reportGridResponseData"]["items"];
          this.dynamicHeaders = gridHeaders;
        }
      },
      (error) => {},
      () => {
        this.GridHighestReturnsResponse = true;
      }
    );
    this.returnService.getProcessingDates().subscribe(
      (res) => {
        if (res) {
          this.returnDateTableData = {
            headers: [
              {
                field: "data",
                headerText: "Sales Channel",
                allowFiltering: true,
                textAlign: "left",
                filter: { type: "Menu" },
              },
              {
                field: "date",
                headerText: "Latest Return Date",
                allowFiltering: false,
                textAlign: "left",
              },
            ],
            data: res["values"],
            displayname: "Return dates recieved by flowsmart",
          };
        }
      },
      (error) => {},
      () => {
        this.prossingResponse = true;
      }
    );
    this.returnService.getScoreCard(this.filtersEvent).subscribe(
      (res) => {
        if (res) {
          this.scoreCardData = res["scoreCardList"];
        }
      },
      (error) => {},
      () => {
        this.scoreCardResponse = true;
      }
    );
  }

  onOptionSelection(event) {
    this.chartJSResponse = false;
    this.filtersEvent.groupBy = event.value;
    if (!this.filtersEvent["startDate"] && !this.filtersEvent["endDate"]) {
      const { startDate, endDate } = this.getLastMonthRange();
      const startDateOut = this.datePipe.transform(startDate, "yyyy-MM-dd");
      const endtDateOut = this.datePipe.transform(endDate, "yyyy-MM-dd");
      this.filtersEvent.startDate = startDateOut;
      this.filtersEvent.endDate = endtDateOut;
    }
    this.returnService.getVisualChart(this.filtersEvent).subscribe(
      (res) => {
        if (res) {
          res["chartJSResponseData"].displayName = null;
          this.visualizationData = res["chartJSResponseData"];
        }
      },
      (error) => {},
      () => {
        this.chartJSResponse = true;
      }
    );
  }

  getFilters(event) {
    this.showOverlay = false;

    this.scoreCardResponse = false;
    this.chartJSResponse = false;
    this.selectedPivot = this.pivotFields[0].toString();
    // this.prossingResponse = false;
    this.GridHighestReturnsResponse = false;
    event.groupBy = this.pivotFields[0].toString();
    this.filtersEvent = {
      ...event,
    };
    this.returnService.getVisualChart(event).subscribe(
      (res) => {
        if (res) {
          res["chartJSResponseData"].displayName = null;
          this.visualizationData = res["chartJSResponseData"];
        }
      },
      (error) => {},
      () => {
        this.chartJSResponse = true;
      }
    );
    this.returnService.getHighestReturns(event).subscribe(
      (res) => {
        if (res) {
          this.gridData = res["reportGridResponseData"]["items"];
          this.dynamicHeaders = gridHeaders;
        }
      },
      (error) => {},
      () => {
        this.GridHighestReturnsResponse = true;
      }
    );
    this.returnService.getScoreCard(event).subscribe(
      (res) => {
        if (res) {
          this.scoreCardData = res["scoreCardList"];
        }
      },
      (error) => {},
      () => {
        this.scoreCardResponse = true;
      }
    );
  }

  getLastMonthRange(): { startDate: Date; endDate: Date } {
    const currentDate = new Date();
    currentDate.setDate(1);
    const startDate = new Date(currentDate);
    startDate.setMonth(currentDate.getMonth() - 1);
    const endDate = new Date(currentDate);
    endDate.setDate(0);
    return { startDate, endDate };
  }
  showFilters() {
    this.showOverlay = true;
  }

  closeFilters(){
    this.showOverlay=false;
  }
  selectedFilters(event) {
    this.addFilters = event;
    this.detectChanges = !this.detectChanges;
  }
}
