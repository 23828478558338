<ng-container>
  <mat-toolbar class="d-flex justify-content-between buttons-align mat-toolbar-single-row">
    <div [formGroup]="formGroup">
      <div class="d-flex name-section">
        <input matInput #inputField formControlName="name" [readonly]="!isEditable" placeholder="Enter a name">
        <mat-icon matTooltip="Edit" class="opacity-50" (click)="focusNameElement()">edit</mat-icon>
      </div>
      <mat-error class="error-message" style="height: 26px;padding-left: 5px;font-size: small;"
        *ngIf="formGroup?.get('name').hasError('minlength')">Enter a valid name</mat-error>
    </div>

    <!-- Actions Containers -->
    <div class="actions-container">
      <button color="primary" style="border: none;background-color: transparent;font-size: initial;outline: none;"
        type="submit" (click)="save()" [disabled]="formGroup?.invalid || scenarioDate">
        <mat-icon matTooltip="Save"
          [ngClass]="{'icon-enabled': !formGroup.invalid && !scenarioDate, 'icon-disabled': formGroup.invalid || scenarioDate}">save</mat-icon>
      </button>
      <button color="primary" style="border: none;background-color: transparent;font-size: initial;outline: none;"
        type="button" (click)="delete($event , formData.code)" [disabled]="!formData || !formData.code || scenarioDate">
        <mat-icon matTooltip="Delete"
          [ngClass]="{'icon-enabled': formData && formData.code && !scenarioDate, 'icon-disabled': !formData || !formData.code || scenarioDate}">delete</mat-icon>
      </button>
      <button color="primary" style="border: none;background-color: transparent;font-size: initial;outline: none;"
        type="submit" (click)="toggleCommentsPopup();fetchComments2(formData?.code);"
        [disabled]="formGroup?.invalid || scenarioDate">
        <mat-icon matTooltip="Comments"
          [ngClass]="{'icon-enabled': !formGroup.invalid && !scenarioDate, 'icon-disabled': formGroup.invalid || scenarioDate}">comments</mat-icon>
      </button>
      <button style="border: none;background-color: transparent;font-size: initial;outline: none;" type="submit"
        (click)="generateForecast();openPanel(1)" [disabled]="!canPredict">
        <mat-icon matTooltip="Generate Forecast">cached</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <div class="comments-popup" [class.active]="isCommentsPopupOpen">
    <div class="comments_header">
      <span class="comments_head"><mat-icon matTooltip="Comments">comments</mat-icon> Comments</span>
      <span class="close_comments" (click)="toggleCommentsPopup()">+</span>
    </div>
    <div class="comments-content">
      <!-- Display the list of comments here -->
      <div *ngFor="let comment of commentsList" class="comment-item">
        <div class="content">
          <span class="userName">{{userId}}</span>
          <span class="comment-text"><b>{{ comment.createdBy }}</b> {{ comment.comment }}
            <br><small>{{ comment.recordDate | date:'short' }}</small>
          </span>
        </div>
      </div>
    </div>
    <!-- Input area for adding comments -->
    <div class="comments-input">
      <input [(ngModel)]="newCommentText" placeholder="Add your comment" (keyup.enter)="addComment()" />
      <button (click)="addComment()">
        <mat-icon matTooltip="add comment">add_circle</mat-icon>
      </button>
    </div>
  </div>

  <!-- Status Tracker Code -->


  <div class="forecast-body">

    <div class="forecast-summary">
      <div class="forecast-steps mat-elevation-z4">

        <div class="stepper-container">
          <div class="step">
            <div class="circle" [ngClass]="{'completed': forecastStepState[0]}">
              <span *ngIf="forecastStepState[0]" class="tick">&#10003;</span>
              <span *ngIf="!forecastStepState[0]">1</span>
            </div>
            <div class="label" title="Define forecast parameters">DEFINE</div>
            <div class="line"></div>
          </div>
          <div class="step">
            <div class="circle" [ngClass]="{'completed': forecastStepState[1]}">
              <span *ngIf="forecastStepState[1]" class="tick">&#10003;</span>
              <span *ngIf="!forecastStepState[1]">2</span>
            </div>
            <div class="label" title="Run prediction">PREDICT</div>
            <div class="line"></div>
          </div>
          <div class="step">
            <div class="circle" [ngClass]="{'completed': forecastStepState[2]}">
              <span *ngIf="forecastStepState[2]" class="tick">&#10003;</span>
              <span *ngIf="!forecastStepState[2]">3</span>
            </div>
            <div class="label" title="Review & approve demand">REVIEW & APPROVE</div>
          </div>
        </div>
        <p>
          Forecasting for the upcoming season is streamlined using the FlowSmart methodology, which includes the
          following steps: Define, Predict, Review, and Approve.
        </p>
      </div>
      <div [formGroup]="formGroup" class="forecast-status mat-elevation-z4">
        <span class="field-class">
          <span class="name">Created on:</span>
          <span class="value">{{formData?.createdTime ? formatDate(formData.createdTime) : "-"}}</span>
        </span>
        <span class="field-class">
          <span class="name">Forecasted from:</span>
          <span class="value">{{formData?.forecastStartDate ? formatDate(formData.forecastStartDate) : "-"}}</span>
        </span>
        <span class="field-class">
          <span class="name">Demand applied on:</span>
          <span class="value">{{formData?.demandConversionDate ? formatDate(formData.demandConversionDate) :
            "-"}}</span>
        </span>
        <span class="actions"><mat-slide-toggle formControlName="active"
            (change)="approveForecastEvent($event)">{{(formData
            != null && formData.active) ? "Approved Forecast" : "Approve this
            Forecast"}}</mat-slide-toggle></span>
      </div>
    </div>

    <mat-accordion>
      <mat-expansion-panel [expanded]="panelOpenState[0]">
        <mat-expansion-panel-header>
          <mat-panel-title class="text">
            <strong>DEFINE</strong>
          </mat-panel-title>
          <mat-panel-description class="text">
            <strong>Finetune your forecast parameters</strong>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <ng-container *ngTemplateOutlet="defineForecastParamsTemplate"></ng-container>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="panelOpenState[1]">
        <mat-expansion-panel-header>
          <mat-panel-title class="text">
            <strong>PREDICT</strong>
          </mat-panel-title>
          <mat-panel-description class="text">
            <strong>Let the machines work for you</strong>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <ng-container *ngTemplateOutlet="predictSalesTemplate"></ng-container>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="panelOpenState[2]">
        <mat-expansion-panel-header>
          <mat-panel-title class="text">
            <strong>REVIEW & APPROVE</strong>
          </mat-panel-title>
          <mat-panel-description class="text">
            <strong>Consensus planning</strong>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div>
          <ng-container *ngTemplateOutlet="measureAndApproveTemplate"></ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <ng-template #defineForecastParamsTemplate>
    <div class="define-parameters">
      <div class="actions">
        <button mat-button class="mat-button mat-primary mat-raised-button" type="submit"
          (click)="generateForecast();openPanel(1)" [disabled]="!canPredict">
          Generate Forecast
        </button>
      </div>
      <div class="form cards-cont" [ngClass]="{'form-readonly' : !isEditable || !canPredict}" [formGroup]="formGroup">

        <div class="properties-card-cont">
          <mat-accordion>
            <mat-expansion-panel hideToggle expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <strong>Properties:</strong>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="display-form">


                <mat-form-field class="duration-cont fs-margin-20">
                  <mat-label>Duration (in months)</mat-label>
                  <mat-select [formControl]="duration">
                    <mat-option [value]="duration"
                      *ngFor="let duration of [1,2,3,4,5,6,7,8,9,10,11,12]">{{duration}}</mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #predictSalesTemplate>
    <div class="predict-sales">
      <div class="actions">
        <button mat-button class="mat-button mat-primary mat-raised-button" type="submit" (click)="generateForecast()"
          [disabled]="!canPredict">
          <mat-icon matTooltip="Generate Forecast">cached</mat-icon> {{(formData && (formData.status === 'Completed' ||
          formData.status === 'Error') && canApprove) ? "Re-compute Forecast" : "Generate Forecast"}}
        </button>
      </div>
      <div [formGroup]="formGroup" class="status-cont d-flex justify-content-between" style="margin: 0 10px;">
        <div class="stepper-container">
          <div *ngFor="let step of statuses; let i = index" class="step"
            [ngClass]="{'completed': (i < predictStepIndex || (predictStepIndex == i && i == statuses.length - 1)),'error': (i == 99 && i == statuses.length - 1)}">
            <div *ngIf="i < statuses.length" class="circle"
              [ngClass]="{'completed': (i < predictStepIndex || (predictStepIndex == i && i == statuses.length - 1)),  'error': (i == 99 && i == statuses.length - 1)}">
              <span *ngIf="i === predictStepIndex && i != statuses.length - 1" class="spinner"></span>
              <span *ngIf="i < predictStepIndex || (predictStepIndex == i && i == statuses.length - 1)"
                class="tick">&#10003;</span>
              <span *ngIf="i > predictStepIndex">{{ i + 1
                }}</span>
            </div>
            <div *ngIf="i < statuses.length" class="label" [title]="step">{{ step }}</div>
            <div *ngIf="i < statuses.length - 1" class="line" [ngClass]="{'completed': i < predictStepIndex}">
            </div>
          </div>
        </div>
      </div>
    </div>


  </ng-template>
  <ng-template #measureAndApproveTemplate>
    <ng-container *ngIf="!formData || revenueForecastData.length < 1">
      <p style="font-style: italic;text-align: center;">Demand forecast not available. Check if you have completed
        Define & Predict steps.</p>
    </ng-container>
    <ng-container *ngIf="displayProjectionResult">
      <div class="measure-approve" [formGroup]="formGroup">
        <div *ngIf="displayProjectionResult" class="actions">
          <div *ngIf="scenarioDate" class="message"> Demand Conversion happened on <strong> {{ formatDate(scenarioDate)
              }}</strong>
          </div>

          <button mat-button class="mat-button mat-secondary mat-raised-button" type="submit"
            (click)="toggleCommentsPopup();fetchComments2(formData?.code);">
            <mat-icon style="font-size: 16px;padding: 5px;" matTooltip="View Comments">comments</mat-icon> Comments
          </button>

          <button mat-button class="mat-button mat-primary mat-raised-button" type="submit" (click)="approveForecast()"
            [disabled]="!canApprove">
            <mat-icon matTooltip="Approve Demand">verified</mat-icon> Approve Demand
          </button>
        </div>


        <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="onTabChange($event)">
          <mat-tab label="REVENUE OVERVIEW">
            <ng-container *ngIf="selectedIndex === 0">
              <!-- Syncfusion Grid for revenue forecast data -->
              <ng-container *ngIf="canApprove">
                <app-syncfusion-grid *ngIf="headerColumns.length > 0" [dynamic_headers]="headerColumns"
                  [reportName]="'RevenueForecasting'" [data]="revenueForecastData" [saveTemplate]="saveTemplate"
                  [freezeColumns]="1" [readOnlyMode]="false">
                </app-syncfusion-grid>
                <ng-template #saveTemplate let-data>
                  <button ejs-button [ngStyle]="{
                    color: editedRows.has(data.id) ? '#FFA900' : 'unset',
                    border: 'unset',
                    background: 'none'
                  }" cssClass="e-primary" title="save" (click)="saveRow(data)">
                    <mat-icon>save</mat-icon>
                  </button>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="!canApprove">
                <app-syncfusion-grid *ngIf="headerColumns.length > 0" [dynamic_headers]="headerColumns"
                  [reportName]="'RevenueForecasting'" [data]="revenueForecastData" [freezeColumns]="1"
                  [readOnlyMode]="true">
                </app-syncfusion-grid>
              </ng-container>
            </ng-container>
            <!-- <app-syncfusion-grid [dynamic_headers]="" [data]="revenueForecastData"
              ></app-syncfusion-grid> -->
          </mat-tab>


          <mat-tab label="CLUSTER REVENUE">
            <!-- Content for the Project Demand tab -->
            <ng-container *ngIf="selectedIndex === 1">
              <app-syncfusion-grid *ngIf="gridColumns.length > 0" reportName="Cluster Average"
                [dynamic_headers]="gridColumns" [data]="clusterPerformanceData" [freezeColumns]="2">
              </app-syncfusion-grid>

            </ng-container>
          </mat-tab>
          <mat-tab label="PRODUCT DEMAND" *ngIf="productDemandData">
            <!-- Content for the Project Demand tab -->
            <ng-container *ngIf="selectedIndex === 2">
              <app-syncfusion-grid *ngIf="gridColumns.length > 0" reportName="Product Demand"
                [dynamic_headers]="productDemandHeaders" [data]="productDemandData" [freezeColumns]="2"
                [readOnlyMode]="true">
              </app-syncfusion-grid>

            </ng-container>
          </mat-tab>
        </mat-tab-group>

      </div>
    </ng-container>

    <div *ngIf="!displayProjectionResult" style="display:flex;justify-content: space-between;">
      <ng-container *ngFor="let number of [0, 1, 2,4,5]">
        <div style="width: 20%;margin-top:20px">
          <p style="
          font-style: normal;
          text-align: center;
          width: 80%;
          height: 10em;
          margin: 0px auto;
        " class="flowsmart-placeholder"></p>
        </div>
      </ng-container>
    </div>
  </ng-template>

</ng-container>