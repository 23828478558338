<div class="mat-elevation-z4 overlay-filters">
  <div class="filters d-flex" *ngIf="isLoading">
    <div class="filter-header">
      <span class="title">FILTERS</span>
      <span mat-icon-button class="close-icon" (click)="closeFilters()">
        <mat-icon>close</mat-icon>
      </span>
    </div>
    <div class="filter-body">
    <ng-container *ngFor="let number of [0, 1, 2, 3, 4]">
      <div class="holder-card mat-elevation-z4">
        <div style="font-weight: lighter; width: 100%; height: 2em" class="flowsmart-placeholder"></div>
        <div style="font-weight: lighter; width: 100%; height: 2em" class="flowsmart-placeholder"></div>
        <div style="font-weight: lighter; width: 100%; height: 2em" class="flowsmart-placeholder"></div>
        <div style="font-weight: lighter; width: 100%; height: 1em" class="flowsmart-placeholder"></div>
      </div>
    </ng-container>
    </div>
  </div>
  <div class="filters d-flex" *ngIf="!isLoading">
    <div class="filter-header">
      <span class="title">FILTERS</span>
      <span mat-icon-button class="close-icon" (click)="closeFilters()">
        <mat-icon>close</mat-icon>
      </span>
    </div>
    <div class="filter-body">
      <ng-container *ngIf="isDateFilter" [formGroup]="dateRangeForm">
        <label>Enter a date range</label>
        <div style="display: flex; gap: 1rem; align-items: center;">
          <!-- Start Date Picker -->

          <div style="">
            <input matInput [matDatepicker]="startPicker" id="startDate" formControlName="startDate"
              placeholder="DD/MM/YYYY" />
            <mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </div> -


          <!-- End Date Picker -->

          <div style="">
            <input matInput [matDatepicker]="endPicker" id="endDate" formControlName="endDate"
              placeholder="DD/MM/YYYY" />
            <mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>

          </div>
        </div>
        <!-- <mat-hint>DD/MM/YYYY</mat-hint> -->

        <!-- <mat-form-field>
      <mat-label>Frequency</mat-label>
      <mat-select #matSelect formControlName="frequency">
        <mat-option value="DAY">DAY</mat-option>
        <mat-option value="WEEK">WEEK</mat-option>
        <mat-option value="MONTH">MONTH</mat-option>
      </mat-select>
    </mat-form-field> -->
      </ng-container>
      <!-- <mat-form-field [formGroup]="filterGroup" *ngFor="let filter of filterDataList">
    <mat-label>{{filter.label}}</mat-label>
    <mat-select [formControlName]="filter.code" #matSelect [multiple]="filter.condition == 'OR' ? true : false"
      panelWidth="auto">
      <mat-select-filter *ngIf="filter.values?.length>5" [array]="filter.values"
        (filteredReturn)="filteredList[filter.code]=$event">
      </mat-select-filter>
      <mat-icon class="close-button" (click)="matSelect.close()" color="primary">close</mat-icon>
      <mat-option (click)="toggleSelectAll(filter.code)" [value]="0">All</mat-option>
      <mat-option *ngFor="let variable of filteredList[filter.code]" value="{{ variable }}"
        (click)="selectSingle(filter.code)">
        {{variable}}
      </mat-option>
    </mat-select>
  </mat-form-field> -->
      <div [formGroup]="filterGroup" *ngFor="let filter of filterDataList" class="filter-container">
        <div class="filter-label">{{filter.label}}
          <button *ngIf="selectedOptions[filter.code]?.length > 0" class="clear-button"
            (click)="clearFilter(filter.code)">
            Clear
          </button>
        </div>
        <div class="filter-options">
          <label *ngFor="let variable of filteredList[filter.code]" class="filter-option">
            <input type="checkbox" [checked]="selectedOptions[filter.code]?.includes(variable)"
              (change)="selectSingle(filter.code , variable)" [value]='variable' class="filter-checkbox" />
            {{ variable }}
          </label>
        </div>
      </div>

    </div>
    <div class="filter-footer">
      <button *ngIf="!isLoading" (click)="closeFilters()" class="mat-button mat-secondary mat-raised-button">
        <mat-icon aria-hidden="false" aria-label="Close Filter Options" fontIcon="close-icon"></mat-icon>
        Close
      </button>
      <button *ngIf="!isLoading" (click)="applyFilters()" class="mat-button mat-primary mat-raised-button">
        <mat-icon aria-hidden="false" aria-label="Apply Filters" fontIcon="filter_alt"></mat-icon>
        Apply
      </button>
    </div>
  </div>

</div>