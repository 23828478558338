<ng-container>
    <mat-toolbar class="justify-content-between">
        <span class="mat-headline fs-margin-0">Supplier Schedule Adherence</span>
    </mat-toolbar>
    <div class="table-graph-container">
        <span class="mat-headline"></span>
        <div class="mat-elevation-z4 table-container">
            <table class="full-width-table" mat-table [dataSource]="dataSource">
                <!-- Product Column -->
                <ng-container matColumnDef="product">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef> </th>
                    <td class="foo" mat-cell *matCellDef="let element"> {{element.product}} </td>
                </ng-container>

                <!-- Type Column -->
                <ng-container matColumnDef="type">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef> </th>
                    <td class="foo" mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef> </th>
                    <td class="foo" mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>

                <!-- Oct-24 Column -->
                <ng-container matColumnDef="oct24">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef>Oct-24</th>
                    <td class="foo" mat-cell *matCellDef="let element"> {{element.oct24}} </td>
                </ng-container>

                <!-- Nov-24 Column -->
                <ng-container matColumnDef="nov24">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef>Nov-24 </th>
                    <td class="foo" mat-cell *matCellDef="let element"> {{element.nov24}} </td>
                </ng-container>

                <!-- Dec-24 Column -->
                <ng-container matColumnDef="dec24">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef>Dec-24</th>
                    <td class="foo" mat-cell *matCellDef="let element"> {{element.dec24}} </td>
                </ng-container>

                <!-- Jan-25 Column -->
                <ng-container matColumnDef="jan25">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef
                        [ngClass]="'green-column'">Jan-25 </th>
                    <td class="foo" mat-cell *matCellDef="let element" [ngClass]="'green-column'"> {{element.jan25}}
                    </td>
                </ng-container>

                <!-- Feb-25 Column -->
                <ng-container matColumnDef="feb25">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef [ngClass]="'blue-column'">
                        Feb-25 </th>
                    <td class="foo" mat-cell *matCellDef="let element" [ngClass]="'blue-column'"> {{element.feb25}}
                    </td>
                </ng-container>

                <!-- Mar-25 Column -->
                <ng-container matColumnDef="mar25">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef [ngClass]="'blue-column'">
                        Mar-25</th>
                    <td class="foo" mat-cell *matCellDef="let element" [ngClass]="'blue-column'"> {{element.mar25}}
                    </td>
                </ng-container>

                <!-- Header and Row Definitions -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <div class="fs-margin-1-0 graph-container">
            <mat-toolbar class="justify-content-between">
                <span class="mat-headline fs-margin-0">Supplier Performance Score</span>
            </mat-toolbar>

            <div class="chart-card">
                <app-dashboard-chatjs-molecule [chartData]="config"
                    [htmlLegend]="false"></app-dashboard-chatjs-molecule>
            </div>
        </div>
    </div>
</ng-container>