<div class="reconciliation-container">
    <app-header-content-help-text [Heading]="reportNameWithSpace"
        [helpText]="'Manual File Upload'"></app-header-content-help-text>
    <div *ngIf="!file" class="mt-3 mb-3 container d-flex align-items-center justify-content-center image-upload"
        (change)="getFile($event)">
        <div>
            <div class="hv-centered  w-100">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-35 text-grey-shaded" viewBox="0 0 24 24"
                        fill="white" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <path d="M12 16v-8"></path>
                        <polyline points="9 11 12 8 15 11"></polyline>
                    </svg>

                </div>
                <div class="font-small"><label class="text-muted drag-text">Drag and drop or</label> <br> <span
                        class="text-blue cursor-pointer">Click to upload</span></div>
            </div>
            <span id="upload"><input id="ember1684" class="upload ember-view form-control" type="file"></span>
        </div>
    </div>
    <div *ngIf="file" class="option-container mt-3 mb-3">
        <div class="option-box">
            <span><strong>File Name:</strong> {{file.name}}</span>
            <br>
            <br>
            <span><strong>Size: </strong>{{formatFileSize(file.size)}}</span>
        </div>
        <div class="dropdown-buttons-container">
            <mat-form-field class="custom-form-field" appearance="outline">
                <mat-label>Choose File Type</mat-label>
                <mat-select [(value)]="selectedOption">
                    <mat-option *ngFor="let type of fileTypes" [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="option-button">
                <button class="button-aligen mr-2" *ngIf="file" mat-flat-button color="primary"
                    (click)="file=undefined">Close</button>
                <button class="button-aligen mr-2" mat-flat-button color="primary" (click)="fileUpload(file)"
                    [disabled]="!selectedOption">
                    <span *ngIf="!isUploading"><i class="bi bi-upload"></i> Upload</span>
                    <mat-spinner *ngIf="isUploading" diameter=" 20" class="spinner"></mat-spinner>
                    <span *ngIf="isUploading">Uploading...</span>
                </button>
            </div>
        </div>
    </div>
    <ejs-grid #grid class="full-width-table" [dataSource]='dataSource' [allowPaging]='true' [allowFiltering]='true'
        [allowSorting]='true' [filterSettings]='filterOption' [allowSelection]='false' [enableHover]='false'
        [enableHeaderFocus]='true'>
        <e-columns>
            <ng-container *ngFor="let header of dynamicHeaders">
                <div *ngIf="header.actions;then actionsTemplate else default"></div>
                <ng-template #actionsTemplate>
                    <e-column [field]="header.field" [headerText]="header.headerText"
                        [headerTextAlign]="header.field === 'Download' ? 'center' : 'left'"
                        [allowFiltering]="header.allowFiltering" [width]="header.width">
                        <ng-template #template let-data>
                            <div *ngIf="header.field === 'Download'" class="download-column cursor-pointer">
                                <i *ngIf="!data.status.includes('Delete')" class="bi bi-download"
                                    style="cursor: pointer;" (click)="downloadFile(data)"></i>
                                <i *ngIf="data.status.includes('Delete')" class="bi bi-ban" title="Cannot download"></i>

                            </div>
                            <div class="d-flex actions-container" *ngIf="header.field === 'Actions'">
                                <button mat-button class="mat-primary mat-raised-button" type="button" color="primary"
                                    [disabled]="data.status !== 'Uploaded'" (click)="processItem(data.id)">
                                    <span *ngIf="!isProcessing || processingRowId !== data.id">Process</span>
                                    <mat-spinner *ngIf="isProcessing && processingRowId == data.id" diameter=" 20"
                                        class="spinner"></mat-spinner>
                                    <span *ngIf="isProcessing && processingRowId == data.id">Processing...</span>
                                </button>
                                <button mat-button class="mat-primary mat-raised-button" color="warn"
                                    [disabled]="data.status.includes('Delete')" (click)="deleteItem(data.id)">
                                    <span *ngIf="!isDeleting || processingRowId !== data.id">Delete</span>
                                    <mat-spinner *ngIf="isDeleting && processingRowId == data.id" diameter=" 20"
                                        class="spinner"></mat-spinner>
                                    <span *ngIf="isDeleting && processingRowId == data.id">Deleting...</span>
                                </button>
                            </div>
                        </ng-template>
                    </e-column>
                </ng-template>
                <ng-template #default>
                    <e-column *ngIf="header.field !== 'recordDate'" [field]="header.field"
                        [headerText]="header.headerText" [width]="header.width" [filter]="header.filter"
                        [allowFiltering]="header.allowFiltering">
                        <ng-template #template let-data>
                            <div matTooltip="{{data[header.field]}}" class="tooltip-text">
                                {{data[header.field]}}
                            </div>
                        </ng-template>
                    </e-column>
                    <e-column *ngIf="header.field == 'recordDate'" [field]="header.field"
                        [headerText]="header.headerText" [width]="header.width"
                        [allowFiltering]="header.allowFiltering"> <ng-template #template let-data>
                            {{ formatDate(data.recordDate) }}
                        </ng-template>
                    </e-column>
                </ng-template>
            </ng-container>
        </e-columns>
    </ejs-grid>
</div>