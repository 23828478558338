import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { SyncfusionService } from "src/app/services/syncfusion.service";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { EmitType } from "@syncfusion/ej2-base";
import { subHeadings } from "src/app/properties/map_sub_heading";
import { AppSettingService } from "src/app/services/app-setting.service";
import { DataResult, DataStateChangeEventArgs } from "@syncfusion/ej2-angular-grids";

@Component({
  selector: "app-sales-forecast",
  templateUrl: "./sales-forecast.component.html",
  styleUrls: ["./sales-forecast.component.scss"],
})
export class SalesForecastComponent implements OnInit {
  dynamicHeaders;
  gridData;
  chartData: any;
  type: any;
  @ViewChild("ejDialog")
  ejDialog!: DialogComponent;
  @ViewChild("container", { read: ElementRef, static: true })
  container!: ElementRef;
  dialogVisible: boolean = false;
  newChartData: any;
  innerDynamicHeaders;
  innerGridData;
  reportName = "ROS_By_Product";
  subHeadings = subHeadings;
  dialogVisibleForAccordion: boolean = false;
  displayNameForLeftGraph = { header: "", rowInfo: { salesChannel: null } };
  displayNameForRightGraph = { header: "", rowInfo: { salesChannel: null } };
  today = new Date();
  code: any;
  rowData: any;
  generatedDate:any;

  constructor(private syncfusion: SyncfusionService, private settingsService: AppSettingService) { }
  ngOnInit(): void {
    if (this.settingsService.isRemoteGridMode()) {
      this.syncfusion.getPaginatedSyncfusionData(this.reportName).subscribe((res) => {
        if (res) {
          this.gridData = (<DataResult>{
            result: res.items,
            count: res.pageData ? res.pageData.totalRecords : res.count
          });
          this.generatedDate = this.gridData?.result[0]?.recordDate || this.today;
          this.dynamicHeaders = JSON.parse(res["headerContent"]);
          this.displayNameForLeftGraph.header = res["displayName"];
          this.syncfusion.setExportData(res);
        }
      });
    } else {
      this.syncfusion.getSyncfusionData(this.reportName).then((res) => {
        this.dynamicHeaders = JSON.parse(res["headerContent"]);
        this.displayNameForLeftGraph.header = res["displayName"];
        this.gridData = res["items"];
        this.generatedDate = this.gridData[0]?.recordDate || this.today;
      });
    }
  }
  navigateGrid(state: DataStateChangeEventArgs) {
    this.syncfusion.getPaginatedSyncfusionData(this.reportName, this.syncfusion.convertGridStateToPageData(state)).subscribe((res) => {
      if (res) {
        this.gridData = (<DataResult>{
          result: res.items,
          count: res.pageData ? res.pageData.totalRecords : res.count
        });
        this.syncfusion.setExportData(res);
      }
    }, (error) => {
      console.error(error);
    })
  }

  chartTypeRecived(event) {
    this.rowData = event.data;
    if (!event.type) {
      this.dialogVisibleForAccordion = true;
      return;
    }
    this.dialogVisible = true;
  }
  public onOverlayClick: EmitType<object> = () => {
    if (this.dialogVisibleForAccordion) {
      this.dialogVisibleForAccordion = false;
      return;
    }
    this.dialogVisible = false;
  };

  @HostListener("document:keydown.escape", ["$event"])
  handleEscKey(event: KeyboardEvent) {
    if (this.dialogVisible) {
      this.dialogVisible = false;
      return;
    }
    if (this.dialogVisibleForAccordion) {
      this.dialogVisibleForAccordion = false;
      return;
    }
  }
}
