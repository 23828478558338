import { Injectable } from '@angular/core';
import { api } from '../properties/endpoints';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReconciliationServiceService {

  constructor(private http: HttpClient) { }

  getFiles(){
    const url=`${api.host}/integration/files/finance/list`;
    return this.http.get<any>(url);
  }
  uploadFiles(file, user,type,subType,) {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('uploadedBy', user);
    formData.append('type', type)
    formData.append('subType', subType);
    const url = `${api.host}/integration/upload`
    return this.http.post(url, formData, { responseType: 'text' })
  }
  deleteFile(id:any){
    const url = `${api.host}/integration/files/finance/delete/${id}`;
    return this.http.delete(url,{responseType: 'text'});
  }
  processFile(payload:any){
    const url = `${api.host}/ai-assistant/finance/process-files`;
    return this.http.post(url,payload);
  }
  getStatus(id:any){
    const url=`${api.host}/integration/files/finance/status/${id}`;
    return this.http.get<any>(url);
  }
  downloadFile(filename: string): Observable<{ blob: Blob, filename: string }>  {
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Accept': 'application/octet-stream'
     });
 
     return this.http.get(`${api.host}/integration/download?filename=${filename}`, {
       headers: headers,
       responseType: 'blob'
     }).pipe(
       map((blob: Blob) => {
         return {
           blob: blob,
           filename: filename
         };
       })
     );
   }
}
