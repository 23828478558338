import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageDifference'
})
export class PercentageDifferencePipe implements PipeTransform {

  transform(value: number, comparisonValue: number): string {
    if (value == null || comparisonValue == null || isNaN(value) || isNaN(comparisonValue)) {
      return '';
    }
    if (comparisonValue === 0) {
      return '0%';
    }
    const percentageDifference = ((value - comparisonValue) / comparisonValue) * 100;
    return percentageDifference.toFixed(2) + '%';
  }

}
