<div class="fs-section-block fs-margin-0 header-background">
  <div class="filter-visible">
    <div>
      <span class="mat-headline">Returns Analyzer</span>
      <p class="fs-hint-text">A complete analysis of Returns across the Channels</p>
    </div>
    <div>
      <button style="height: fit-content;" (click)="showFilters()" class="mat-button mat-primary mat-raised-button">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>
        Filters
      </button>
    </div>
  </div>

    <app-filter-molecule (filterOutput)="getFilters($event)" [hidden]="!showOverlay" (closeFiltersOutput)="closeFilters()"  (addFiltersOut)="selectedFilters($event)"
      [returnsDateRange]="this.filtersEvent" [detectChangesFromParent]="detectChanges"
      [monthFieldRequired]="true"></app-filter-molecule>
</div>
<app-filter-bay-reusable *ngIf="addFilters" [addFilters]="addFilters"></app-filter-bay-reusable>
<div *ngIf="!scoreCardResponse">
  <div class="charts-box">
    <ng-container *ngFor="let number of [0, 1,2,3]">
      <div class="mat-elevation-z4 fs-padding-1 chart-section fs-margin-0-1">
        <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs.padding-2-0"></p>
        <p class="flowsmart-placeholder placeholder-template fs-padding-1"></p>
        <p class="flowsmart-placeholder placeholder-template fs-padding-2"></p>
        <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs.padding-2-0"></p>
      </div>
    </ng-container>
  </div>
</div>
<div class="returnsContainer">
  <div class="scorecard-row fs-flex fs-margin-1-0 fs-padding-0-1 justify-content-between">
    <ng-container *ngIf="scoreCardResponse">
      <div class="scorecard-container">
        <ng-container *ngFor="let scorecard of scoreCardData">
          <div class="score-card mat-elevation-z4 {{scorecard.code}}">
            <div class="name">
              {{ scorecard.name | capitalize }}
            </div>
            <div class="value-container">
              <div class="icon">
                <!--populated through css-->
              </div>
              <div class="value">
                <div>
                  {{scorecard.value}}<span *ngIf="scorecard.code == 'return_percentage'">%</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class="return-breakdown" *ngIf="chartJSResponse">
      <mat-toolbar>
        <span class="mat-headline fs-margin-0">Returns by</span>
        <mat-form-field *ngIf="pivotFields" class="fs-padding-0-1 mat-body-1">

          <mat-select [(value)]="selectedPivot" [multiple]="false" panelWidth="max-content"
            (selectionChange)="onOptionSelection($event)">
            <mat-option *ngFor="let item of pivotFields" [value]="item">{{
              item|capitalize
              }}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-toolbar>
      <div class="fs-padding-0-1">
        <app-dashboard-chatjs-molecule *ngIf="chartJSResponse && visualizationData"
          [header]="visualizationData.displayName" [chartData]="visualizationData"></app-dashboard-chatjs-molecule>
      </div>
    </div>
  </div>


  <div *ngIf="GridHighestReturnsResponse">
    <mat-toolbar _ngcontent-jvi-c115="" class="mat-toolbar justify-content-between mat-toolbar-single-row">
      <span class="fs-secondary-text">Products Returns Analyser</span>
    </mat-toolbar>
    <div class="return-product-table fs-padding-0-1">

      <!-- <span class="mat-headline">Availability Monitoring</span> -->
      <app-syncfusion-grid htmlLegend="true" class="fs-padding-1" *ngIf="gridData" [dynamic_headers]="dynamicHeaders"
        [data]="gridData"></app-syncfusion-grid>
    </div>
  </div>
</div>
<!--<div *ngIf="returnDateTableData" class="return_date_table">
  <app-dashboard-grids-molecule class="chartjs-charts"
  [header]="returnDateTableData.headers" [chartData]="returnDateTableData" [frozenColumns]="0"  [excelExport]="false"></app-dashboard-grids-molecule>
</div> -->