<ng-container>
    <mat-toolbar class="toolbar d-flex justify-content-between buttons-align mat-toolbar-single-row">
        <div class="d-flex name-section">
            <span>{{formData?.code ? formData?.code : 'Create Quote - # no'}}</span>
        </div>
        <div class="delivery-details-cont">
            <span class="heading">Delivery ETA</span>
            <span class="date">{{formData?.customDeliveryDate ? formData?.customDeliveryDate : '-'}}</span>
        </div>
        <!-- Actions Containers -->
        <div class="actions-container">
            <button color="primary" class="icon-button" type="button" (click)="deleteInquiry(formData?.id)">
                <mat-icon matTooltip="Delete Inquiry">delete</mat-icon>
            </button>
            <button class="icon-button" type="submit" (click)="shareInquiry(formData?.id)">
                <mat-icon matTooltip="Share Inquiry">share</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <div class="details-container" [formGroup]="formGroup">
        <div class="inputs-container">
            <div class="input-field">
                <mat-form-field>
                    <mat-label class="label">Lookup Customers</mat-label>
                    <input matInput placeholder="Enter Id or Name" formControlName="lookupCustomer">
                    <mat-icon matSuffix class="clear-icon" *ngIf="formGroup.get('lookupCustomer')?.value"
                        (click)="clearInputField('lookupCustomer')">close</mat-icon>
                </mat-form-field>
                <button mat-button class="mat-button mat-primary mat-raised-button lookup-btn" type="button"
                    [disabled]="!formGroup.get('lookupCustomer')?.dirty || !formGroup.get('lookupCustomer')?.value"
                    (click)="onLookup('lookupCustomer')">Lookup</button>
            </div>
            <div class="input-field">
                <mat-form-field>
                    <mat-label class="label">Lookup Quality/Design</mat-label>
                    <input matInput placeholder="Enter value" formControlName="lookupQualityDesign">
                    <mat-icon matSuffix class="clear-icon" *ngIf="formGroup.get('lookupQualityDesign')?.value"
                        (click)="clearInputField('lookupQualityDesign')">close</mat-icon>
                </mat-form-field>
                <button mat-button class="mat-button mat-primary mat-raised-button lookup-btn" type="button"
                    [disabled]="!formGroup.get('lookupQualityDesign')?.dirty || !formGroup.get('lookupQualityDesign')?.value"
                    (click)="onLookup('lookupQualityDesign')">Lookup</button>
            </div>
        </div>
        <div class="customer-details-cont">
            <div class="name-contact-row">
                <div class="detail-item">
                    <span class="label">Customer Name: </span>
                    <span class="value">{{ customerDetails.name || '-' }}</span>
                </div>
                <div class="detail-item" style="flex: none;padding-right: 0px;">
                    <span class="label">Contact: </span>
                    <span class="value">{{ customerDetails.contact || '-' }}</span>
                </div>
            </div>
            <div class="address-row">
                <div class="detail-item">
                    <span class="label">Address: </span>
                    <span class="value">{{ customerDetails.address || '-' }}</span>
                </div>
                <button mat-button class="mat-button mat-primary mat-raised-button lookup-btn" type="button"
                    (click)="openSales360()">Sales
                    360</button>
            </div>
        </div>
    </div>
    <div class="fs-margin-1-0">
        <!-- Material Lookup Table -->
        <div *ngIf="materialLookupData.length && materialLookupHeaders.length > 0;"
            class="mat-elevation-z8 inquiry-materials-table-cont">
            <div class="table-container">
                <table mat-table class="full-width-table" [dataSource]="materialLookupData">
                    <ng-container *ngFor="let column of materialLookupHeaders" [matColumnDef]="column">
                        <th mat-header-cell *matHeaderCellDef class="table_header table_cell foo">{{
                            inquiryMaterialColumns[column] }}</th>
                        <td class="foo" mat-cell *matCellDef="let element">
                            <ng-container *ngIf="column === 'orderQty' || column === 'price'; else readOnly">
                                <input type="text" matInput [(ngModel)]="element[column]" (input)="checkInputs()" />
                            </ng-container>
                            <ng-template #readOnly>
                                {{ element[column] }}
                            </ng-template>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="materialLookupHeaders"></tr>
                    <tr mat-row *matRowDef="let row; columns: materialLookupHeaders;"></tr>
                </table>
            </div>
            <button mat-button class="mat-button mat-primary mat-raised-button add-items-button"
                [disabled]="!isAddItemsButtonEnabled" (click)="addItemsToInquiry()" type="button">Add Items</button>
        </div>
        <mat-toolbar class="justify-content-between">
            <span class="mat-headline fs-margin-0">Items</span>
        </mat-toolbar>
        <!-- Inquiry Items Table -->
        <div *ngIf="inquiryItemsData && inquiryItemsData.length > 0; else noRecordsTemplate"
            class="inquiry-items-table-cont">
            <div class="table-container">
                <table mat-table class="full-width-table" [dataSource]="inquiryItemsData">
                    <ng-container *ngFor="let column of inquiryItemsHeaders" [matColumnDef]="column">
                        <ng-container *ngIf="column !== 'save'; else actionColumn">
                            <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef>
                                {{ inquiryItemsColumns[column] }}
                            </th>
                            <td class="foo" mat-cell *matCellDef="let element">
                                <ng-container *ngIf="column === 'customDeliveryDate'; else readOnly">
                                    <mat-form-field style="width: 150px;">
                                        <input matInput [matDatepicker]="picker" [(ngModel)]="element[column]"
                                            (dateChange)="onDateChange($event, element, column)"
                                            [value]="element.computedDeliveryDate ? element.computedDeliveryDate : element.customDeliveryDate" />
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </ng-container>
                                <ng-template #readOnly>
                                    {{ element[column] }}
                                </ng-template>
                            </td>
                        </ng-container>
                        <!-- Action column-->
                        <ng-template #actionColumn>
                            <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef>
                                Actions
                            </th>
                            <td class="foo" style="display: flex;justify-content: center;" mat-cell
                                *matCellDef="let element">
                                <button class="save-icon-btn" [ngStyle]="{ color: enableSave ? '#ffa100' : '#ccc' }"
                                    mat-icon-button [disabled]="!enableSave" (click)="onSave(element)">
                                    <mat-icon>save</mat-icon>
                                </button>
                                <button class="delete-icon-button" mat-icon-button (click)="deleteItem(element)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-template>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="inquiryItemsHeaders"></tr>
                    <tr mat-row *matRowDef="let row; columns: inquiryItemsHeaders;"></tr>
                </table>
            </div>
        </div>
        <ng-template #noRecordsTemplate>
            <span style="margin: 15px;">No records found</span>
        </ng-template>
    </div>
    <div class="comment-section" [formGroup]="commentForm" *ngIf="inquiryItemsData.length > 0">
        <div>
            <div>
                <button mat-button class="no-style-button">
                    <span class="account_icon">{{userName}}</span>
                </button>
                <div class="comment-cont">
                    <textarea matInput placeholder="Add Comment" rows="2" formControlName="comment" style="margin: 7px;"
                        value="comment"></textarea>
                    <button mat-button class="save-btn" (click)="createComment()" [disabled]="commentForm.invalid"
                        *ngIf="(commentForm.get('comment')?.dirty)">Save</button>
                </div>
            </div>
        </div>
        <div class="comments-list-cont">
            <div class="list-item" *ngFor="let comment of commentsList">
                <button mat-button class="no-style-button">
                    <span class="account_icon">{{getCommentUser(comment.createdBy)}}</span>
                </button>
                <div class="text-cont">
                    <div class="text-header">
                        <span>
                            <strong class="user-name">{{comment.createdBy}}</strong>
                            <span>&nbsp;</span>
                            <span>added a <strong>Comment</strong> <span>&nbsp;</span> {{ comment.recordDate |
                                date:'MMMM dd, yyyy '}} at {{ comment.recordDate | date:'h:mm a'}}</span></span>
                    </div>
                    <div class="text-content">{{comment.comment}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #overlayTemplate>
    <div class="overlay-panel" [@childAnimation]>
        <div class="overlay-header">
            <div>Customer Sales 360 Deg</div>
            <mat-icon class="close-icon" (click)="closeOverlay()">close</mat-icon>
        </div>
        <!-- Inquiry orders table -->
        <div *ngIf="customerDetails?.id" class="table-container">
            <div *ngIf="!customerSalesData?.length" class="no-data-message">
                No sales data found
            </div>
            <table *ngIf="customerSalesData?.length" mat-table class="full-width-table"
                [dataSource]="customerSalesData">
                <ng-container *ngFor="let columnKey of customerSalesHeaders" [matColumnDef]="columnKey">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef>
                        {{ inquiryOrdersColumns[columnKey] }}
                    </th>
                    <td class="foo" mat-cell *matCellDef="let element"> {{ element[columnKey] }} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="customerSalesHeaders"></tr>
                <tr mat-row *matRowDef="let row; columns: customerSalesHeaders;"></tr>
            </table>
        </div>
    </div>
</ng-template>
<ng-template #dialogTemplate let-dialogRef="dialogRef">
    <h2 mat-dialog-title>Select Customer</h2>
    <mat-dialog-content>
        <mat-form-field class="dialog-mat-form-field">
            <mat-label>Select a Customer</mat-label>
            <mat-select [(value)]="selectedCustomer">
                <mat-option *ngFor="let customer of customersList" [value]="customer">
                    {{ customer.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="dialogRef.close()">Cancel</button>
        <button mat-button color="primary" (click)="confirmCustomerSelection(dialogRef)">Confirm</button>
    </mat-dialog-actions>
</ng-template>
