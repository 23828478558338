<div class="vertical-loader" *ngIf="isLoading">
  <ng-container *ngFor="let number of [0, 1, 2, 3, 4]">
    <div class="holder-card mat-elevation-z4">
      <div style="font-weight: lighter; width: 60%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 80%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 80%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 95%; height: 1em" class="flowsmart-placeholder"></div>
    </div>
  </ng-container>
</div>
<div *ngIf="!isLoading && dynamic_headers?.length>0" class="table-container">
  <div class="info"><span>*Real Share of sale represents the true ratio including lost sales due to unavailability.</span></div>
  <app-syncfusion-grid [reportName]="'Variant_Mix'" [dynamic_headers]="dynamic_headers" [data]="gridData"></app-syncfusion-grid>

</div>