import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ReOrderingService {

  constructor(private http: HttpClient) { }

  generateReOrdering(payload: any) {
    const url = `${api.host}/ai-assistant/neemans/reordering/generate `;
    return this.http.post(url, payload);
  }
  getStatus() {
    const url = `${api.host}/ai-assistant/neemans/reordering/status`;
    return this.http.get(url);
  }
  generateReComputing(payload: any) {
    const url = `${api.host}/ai-assistant/neemans/reordering/calculate `;
    return this.http.post(url, payload);
  }
  getReOrderingData() {
    const url = `${api.host}/reports/grid/Neemans-Reordering`;
    return this.http.get(url);
  }
  getReOrderingSummary() {
    const url = `${api.host}/reports/grid/Neemans-Reordering-Summary`;
    return this.http.get(url);
  }
  upload(payload:any){
    const url = `${api.host}/material/forecast/projection-update/neemans/upload`;
    return this.http.post(url,payload, { responseType: 'text' });
  }
}
