<mat-toolbar class="header display-flex justify-content-center">
  <span>{{data[headerField.decisionAttr]}} - {{data.itemDescription}}{{data.materialDescription}}</span>
</mat-toolbar>

<app-detail-page-molecule [disableRoute]="true"  [getCodeFromParent]="data[headerField.decisionAttr]"></app-detail-page-molecule>

<!-- <mat-accordion>
  <mat-expansion-panel [expanded]="expansionPannelROS===headerField.accordian" (opened)='triggerOpenEvent("Rate of Sale")'>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Rate of Sale and Discount offered</strong>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-pu-ros-molecule [data]="data" [code]="data[headerField.decisionAttr]" [defaultChartLabels]="defaultChartLabels"></app-pu-ros-molecule>

  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="expansionPannelSET===headerField.accordian" (opened)='triggerOpenEvent("Stock Trend")'>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Stock and Eco Trend</strong>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-pu-stock-trend-molecule [code]="data[headerField.decisionAttr]"></app-pu-stock-trend-molecule>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="expansionPannelBPR===headerField.accordian" (opened)='triggerOpenEvent("Product Availability")'>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Product Availablity</strong>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-pu-bpr-molecule [code]="data[headerField.decisionAttr]"></app-pu-bpr-molecule>
  </mat-expansion-panel>
</mat-accordion> -->
