import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccessRulesPipe } from "./access-rules.pipe";
import { CapitalizePipe } from "./capitalize.pipe";
import { ImageUrl } from "./image-url.pipe";
import { CurrencyFormatPipe } from "./number-conversion.pipe";
import { TrendComparator, TrendDirection } from "./trend-comparator.pipe";
import { PercentageDifferencePipe } from './percentage-difference.pipe';

@NgModule({
  declarations: [AccessRulesPipe, CapitalizePipe, CurrencyFormatPipe, ImageUrl,TrendComparator,TrendDirection, PercentageDifferencePipe],
  imports: [CommonModule],
  exports: [AccessRulesPipe, CapitalizePipe, CurrencyFormatPipe, ImageUrl,TrendComparator,TrendDirection, PercentageDifferencePipe],
})
export class PipesModule {}
