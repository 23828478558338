<div *ngIf="config; else notFound" class="fs-padding custom-obj-container">
    <div class="fs-section-block fs-margin-0 header-background ">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <span class="mat-headline">{{config?.title}}</span>
                <p class="fs-hint-text mt-1">{{config?.summary}}</p>
            </div>
        </div>
    </div>
    <div class="actions">
        <button class="save-btn mat-button mat-primary mat-raised-button" (click)="saveForm()">Save</button>
    </div>
    <div class="sections-container" *ngIf="dynamicForm">
        <div *ngFor="let section of config.sections">
            <ng-container *ngIf="section.title; else noTitle">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ section.title }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="fields-grid">
                        <ng-container *ngFor="let fieldCode of section.fields">
                            <ng-container
                                *ngTemplateOutlet="renderField; context: { field: getFieldByCode(fieldCode) }"></ng-container>
                        </ng-container>
                    </div>
                </mat-expansion-panel>
            </ng-container>
            <ng-template #noTitle>
                <div class="fields-grid">
                    <ng-container *ngFor="let fieldCode of section.fields">
                        <ng-container
                            *ngTemplateOutlet="renderField; context: { field: getFieldByCode(fieldCode) }"></ng-container>
                    </ng-container>
                </div>
            </ng-template>
        </div>
        <div *ngIf="!config.sections || config.sections.length === 0" class="fields-grid">
            <ng-container *ngFor="let field of config.fields">
                <ng-container *ngTemplateOutlet="renderField; context: { field }"></ng-container>
            </ng-container>
        </div>
        <ng-template #renderField let-field="field">
            <div [formGroup]="dynamicForm" class="fields-container" *ngIf="field.initial">
                <ng-container [ngSwitch]="field.display_type">
                    <!-- Input Field -->
                    <div *ngSwitchCase="'input'">
                        <mat-form-field appearance="fill">
                            <mat-label class="label">{{ field.label }}</mat-label>
                            <input matInput [formControlName]="field.code" placeholder="{{ field.label }}">
                            <mat-hint align="start">
                                <mat-icon class="info-icon">info</mat-icon>
                                <span class="info-text">{{ field.info }}</span>
                            </mat-hint>
                            <mat-error *ngIf="dynamicForm.get(field.code).hasError('required')">
                                {{ field.label }} is required.
                            </mat-error>
                            <mat-error *ngIf="dynamicForm.get(field.code).hasError('minlength')">
                                Minimum {{ field.validators?.minLength }} characters are required.
                            </mat-error>
                            <mat-error *ngIf="dynamicForm.get(field.code).hasError('maxlength')">
                                Maximum {{ field.validators?.maxLength }} characters are required.
                            </mat-error>
                            <mat-error *ngIf="dynamicForm.get(field.code).hasError('pattern')">
                                Invalid format for {{ field.label }}.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Select Field -->
                    <div *ngSwitchCase="'select'">
                        <mat-form-field appearance="fill">
                            <mat-label class="label">{{ field.label }}</mat-label>
                            <mat-select [formControlName]="field.code">
                                <mat-option *ngFor="let option of field.values"
                                    [value]="option.code">{{option.label}}</mat-option>
                            </mat-select>
                            <mat-hint align="start">
                                <mat-icon class="info-icon">info</mat-icon>
                                <span class="info-text">{{ field.info }}</span>
                            </mat-hint>
                            <mat-error *ngIf="dynamicForm.get(field.code).hasError('required')">This field is
                                required.</mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Radio Field -->
                    <div *ngSwitchCase="'radio'" class="radio-cont">
                        <mat-label class="label" style="font-weight: 500;">{{ field.label }}</mat-label>
                        <mat-radio-group [formControlName]="field.code" style="margin: 4px 0px 2px 0px ;">
                            <div *ngFor="let option of field.values">
                                <mat-radio-button [value]="option.code">{{ option.label }}</mat-radio-button>
                            </div>
                        </mat-radio-group>
                        <div class="d-flex" style="line-height: 0.8;">
                            <mat-icon
                                style="font-size: 0.6em;margin-right: 2px;width: 1em;height: 1em;color: #ffa000;">info</mat-icon>
                            <span style="font-size: 0.7em;font-style: italic;color: #000;">{{ field.info }}</span>
                        </div>
                        <mat-error *ngIf="dynamicForm.get(field.code).hasError('required')">
                            Please select an option for {{ field.label }}.
                        </mat-error>
                    </div>

                    <!-- Calendar Field (Date Picker) -->
                    <div *ngSwitchCase="'calendar'">
                        <mat-form-field appearance="fill">
                            <mat-label class="label">{{ field.label }}</mat-label>
                            <input matInput [matDatepicker]="picker" [formControlName]="field.code"
                                placeholder="Choose a date">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-hint align="start">
                                <mat-icon class="info-icon">info</mat-icon>
                                <span class="info-text">{{ field.info }}</span>
                            </mat-hint>
                            <mat-error *ngIf="dynamicForm.get(field.code).hasError('required')">
                                {{ field.label }} is required.
                            </mat-error>
                            <mat-error *ngIf="dynamicForm.get(field.code).hasError('matDatepickerParse')">
                                Invalid date format.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Textarea -->
                    <div *ngSwitchCase="'textarea'">
                        <mat-form-field appearance="fill">
                            <mat-label class="label">{{ field.label }}</mat-label>
                            <textarea matInput [formControlName]="field.code" placeholder="{{ field.label }}"
                                rows="3"></textarea>
                            <mat-hint align="start">
                                <mat-icon class="info-icon">info</mat-icon>
                                <span class="info-text">{{ field.info }}</span>
                            </mat-hint>
                            <mat-error *ngIf="dynamicForm.get(field.code).hasError('required')">
                                {{ field.label }} is required.
                            </mat-error>
                            <mat-error *ngIf="dynamicForm.get(field.code).hasError('minlength')">
                                Minimum {{ field.validators?.minLength }} characters are required.
                            </mat-error>
                            <mat-error *ngIf="dynamicForm.get(field.code).hasError('maxlength')">
                                Maximum{{ field.validators?.maxLength }} characters are required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </div>
</div>

<ng-template #notFound>
    <div class="text-center mt-5">
        <i class="bi bi-emoji-frown not-found-icon"></i>
        <h1 class="mat-display-1 not-found-header" style="margin: 0px 0px 10px;">404 Not Found</h1>
        <span class="mr-2">Oops! The page you are looking for doesn't exist</span>
        <button mat-raised-button color="primary" routerLink="/">Return Home</button>
    </div>
</ng-template>