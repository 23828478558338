<div class="fs-padding">
  <div class="fs-section-block fs-margin-0 header-background ">
    <div class="filter-visible">
      <div>
        <span class="mat-headline">Revenue Insights</span>
        <p class="fs-hint-text">A Comprehensive Sales Summary Analysis</p>
      </div>
      <button style="height: fit-content;" (click)="showFilters()" class="mat-button mat-primary mat-raised-button">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>
        Filters
      </button>
    </div>
      <app-filter-molecule [hidden]="!showOverlay" (closeFiltersOutput)="closeFilters()"  (addFiltersOut)="getFilters($event)" [isDateFilter]="true"
        (filterOutput)="selectedFilters($event)" [detectChangesFromParent]="changesDetected"></app-filter-molecule>
        
  </div>
  <app-filter-bay-reusable *ngIf="addFilters" [addFilters]="addFilters"></app-filter-bay-reusable>

  <div class="fs-section-block">
    <app-business-score-card [changesFromParent]="changesFromParent"></app-business-score-card>
  </div>
  <div class="fs-margin-top-1">
    <app-sales-breakdown-molecule [changesFromParent]="changesFromParent"></app-sales-breakdown-molecule>
  </div>

  <div class="fs-margin-1-0">
    <mat-toolbar class="justify-content-between">
      <span class="mat-headline fs-margin-0">Revenue Trend</span>
    </mat-toolbar>
    <app-sales-visualization-molecule [changesFromParent]="changesFromParent"></app-sales-visualization-molecule>
  </div>

</div>