import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataResult, DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { CustomObjectsService } from 'src/app/services/custom-objects.service';
import { SyncfusionService } from 'src/app/services/syncfusion.service';

@Component({
  selector: 'app-custom-objects-list-type-molecule',
  templateUrl: './custom-objects-list-type-molecule.component.html',
  styleUrls: ['./custom-objects-list-type-molecule.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease',
          style({ opacity: 1 })
        ),
        query("@*", [animateChild()], { optional: true })
      ]),
      transition(':leave', [
        query("@*", [animateChild()], { optional: false }),
      ]),
    ]),
    trigger('childAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('800ms cubic-bezier(0.2, 1, 0.3, 1)',
          style({ transform: 'translateX(0%)' })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('300ms ease',
          style({
            transform: 'translateX(100%)',
            boxShadow: '0px 0 00px 0px rgba(87,73,86,0.0)'
          }
          )
        )
      ])
    ])
  ]
})
export class CustomObjectsListTypeMoleculeComponent implements OnInit {
  @ViewChild('overlayTemplate', { static: false }) overlayTemplate!: TemplateRef<any>;
  @Input() config: any;
  overlayRef!: OverlayRef;
  dynamic_headers: any;
  data: any;
  dynamicForm!: FormGroup;
  isEditMode = false;
  visiblePOPUP: boolean;
  fieldData: any;
  headerData: any;
  count: any;
  tableDataFetched: boolean = false;
  headers: any;
  editObjId: any;
  pageData: any = {};
  configName: any;

  constructor(private fb: FormBuilder, private overlay: Overlay, protected syncfusion: SyncfusionService,
    private viewContainerRef: ViewContainerRef, private snack: MatSnackBar, private settingsService: AppSettingService,
    private customService: CustomObjectsService) { }
    ngOnInit() {
      if (this.config) {
        this.configName = this.config?.code;
        this.dynamic_headers = this.getGridHeaders();
        if (this.settingsService.isRemoteGridMode()) {
          this.customService.getPaginatedCustomObjList(this.configName, this.pageData).subscribe((res) => {
            if (res) {
              let rawData = res['items'];
              this.data = (<DataResult>{
                result: this.mapCodesToLabels(rawData),
                count: res['pageData'] ? res['pageData']['totalRecords'] : res['count']
              });
              this.syncfusion.setExportData(res);
              setTimeout(() => {
                this.tableDataFetched = true;
              });
            }
          });
        } else {
          this.customService.getPaginatedCustomObjList(this.configName, this.pageData).subscribe((res) => {
            this.data = res["items"];
            setTimeout(() => {
              this.tableDataFetched = true;
            });
          });
        }
        this.generateForm();
      }
  
    }
    getGridHeaders() {
      return this.config?.fields?.filter((obj: any) => obj?.list_view === true).map(field => ({
        field: field.code,
        headerText: field.label,
        allowFiltering: field.allowFiltering ?? true,
        filter: { type: field.filterType || 'Menu' },
        width: field.width || (field.code === 'name' ? '200px' : '180px'),
        type: field.display_type,
      })).concat([{
        field: 'actions',
        headerText: 'Actions',
        allowFiltering: false,
        filter: { type: null },
        width: '100px',
        type: 'actions',
      }]);
    }
    mapCodesToLabels(data: any[]): any[] {
      if (!this.config?.fields) return data;
  
      return data.map((row) => {
          const mappedRow = { ...row };
  
          this.config.fields.forEach((field: any) => {
            if (row[field.code] === "" || row[field.code] === null || row[field.code] === undefined) {
              // Replace empty, null, or undefined values with a dash
              mappedRow[field.code] = "-";
          } else if(field.values && field.values.length) {
                  const matchedValue = field.values.find((option: any) => option.code === row[field.code]);
                  if (matchedValue) {
                      mappedRow[field.code] = matchedValue.label; // Replace code with label
                  }
              }
          });
  
          return mappedRow;
      });
  }
    createOverlay() {
      if (!this.overlayRef) {
        this.overlayRef = this.overlay.create({
          hasBackdrop: true,
          backdropClass: 'overlay-backdrop',
          positionStrategy: this.overlay.position().global().right('0px').top('0px'),
          scrollStrategy: this.overlay.scrollStrategies.block(),
          height: '100vh',
          width: '400px'
        });
        const templatePortal = new TemplatePortal(this.overlayTemplate, this.viewContainerRef);
        Promise.resolve().then(() => this.overlayRef.attach(templatePortal));
        this.overlayRef.backdropClick().subscribe(() => {
          this.closeOverlay();
        });
      }
    }
    closeOverlay() {
      if (this.overlayRef) {
        this.overlayRef.dispose();
        this.overlayRef = null!;
        this.isEditMode = false;
        this.dynamicForm.reset();
      }
    }
    generateForm() {
      const controls = this.config?.fields
        .filter((field: any) => field.initial)
        .reduce((acc: any, field: any) => {
          acc[field.code] = this.fb.control('', this.mapValidators(field?.validators));
          return acc;
        }, {});
  
      this.dynamicForm = this.fb.group(controls);
    }
    mapValidators(validators: Record<string, any>): any[] {
      const validatorMapping: Record<string, (value: any) => any> = {
        required: () => Validators.required,
        minLength: (value: number) => Validators.minLength(value),
        maxLength: (value: number) => Validators.maxLength(value),
        pattern: (value: string) => Validators.pattern(value),
      };
      return Object.entries(validators)
      .map(([key, value]) => value !== false ? validatorMapping[key]?.(value) : null)  // Only map valid values
      .filter(Boolean); 
    }
    
    createForm() {
      this.isEditMode = false;
      this.createOverlay();
    }
  
    onEdit(args: { id: any; event: MouseEvent }): void {
      const { id, event } = args;
      event.stopPropagation();
      this.customService.getCustomObjectbyId(this.configName, id).subscribe((res: any) => {
        this.dynamicForm.patchValue(res);
        this.createOverlay();
        this.isEditMode = true;
        this.editObjId = res.id;
      }, (error) => {
        console.error('Error while fetching object by ID:', error);
        this.showSnackbar('Error while fetching the object');
      })
    }
    compareType(option1: any, option2: any): boolean {
      if (option1 && option2) {
        return option1.name === option2.name;
      }
      return false;
    }
  
    onDelete(args: { id: any; event: MouseEvent }): void {
      const { id, event } = args;
      event.stopPropagation();
      this.customService.deleteCustomObj(this.configName, id).subscribe({
        next: () => {
          this.showSnackbar('Custom object deleted successfully!');
          this.getCustomObjList();
        },
        error: (error) => {
          this.showSnackbar('Unable to delete custom object');
          console.error(error);
        }
      });
    }
    showSnackbar(message: string) {
      this.snack.open(message, 'close', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }
    tableData: any;
    getCustomObjList() {
      this.customService.getPaginatedCustomObjList(this.configName, this.pageData).subscribe((res: any) => {
        if (res) {
          this.data = this.mapCodesToLabels(res?.items);
          setTimeout(() => {
            this.tableDataFetched = true;
          });
        }
      }, (error) => {
        this.tableDataFetched = true;
        console.error(error);
      })
    }
    markAllControlsAsTouched(formGroup: FormGroup): void {
      Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormGroup) {
          this.markAllControlsAsTouched(control);
        } else {
          control?.markAsTouched();
        }
      });
    }
    onToggleChange(event: MatSlideToggleChange, field: any) {
      const isChecked = event.checked;
      this.dynamicForm.get(field.code)?.setValue(isChecked);
  }
  
    saveForm() {
      this.markAllControlsAsTouched(this.dynamicForm);
      if (this.dynamicForm.valid) {
        const payload = this.dynamicForm.value;
        if (this.isEditMode) {
          this.customService.updateCustomObj(this.configName, payload, this.editObjId).subscribe((res: any) => {
            this.showSnackbar('Custom object updated successfully!');
            this.closeOverlay();
            this.getCustomObjList();
          }, (error) => {
            this.showSnackbar('Unable to create custom object');
            this.closeOverlay();
          });
  
  
        } else {
          this.customService.createCustomObj(this.configName, payload).subscribe((res) => {
            this.showSnackbar('Custom object created successfully!');
            this.closeOverlay();
            this.getCustomObjList();
          },
            (error) => {
              this.showSnackbar('Unable to create custom object');
              this.closeOverlay();
            })
        }
      }
    }
    handlePOPUP(event) {
      this.fieldData = event.fieldData;
      this.headerData = event.header;
      this.visiblePOPUP = true;
    }
  
    navigateGrid(state: DataStateChangeEventArgs) {
      this.customService.getPaginatedCustomObjList(this.configName, this.syncfusion.convertGridStateToPageData(state)).subscribe((res) => {
        if (res) {
          this.data = (<DataResult>{
            result: res['items'],
            count: res['pageData'] ? res['pageData']['totalRecords'] : res['count']
          });
          this.syncfusion.setExportData(res);
          setTimeout(() => {
            this.tableDataFetched = true;
          });
        }
      }, (error) => {
        this.tableDataFetched = true;
        console.error(error);
      })
    }
  
  }
