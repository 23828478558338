import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { FilterUiService } from 'src/app/services/filter-ui.service';
import { subHeadings } from "src/app/properties/map_sub_heading";
import { ListingGridService } from 'src/app/services/listing-grid.service';
import { DatePipe } from "@angular/common";
import { FormGroup, FormControl } from "@angular/forms";

import { CommonUtilityService } from "src/app/services/common-utility-service";



@Component({
  selector: "app-products-sales-analyzer",
  templateUrl: "./products-sales-analyzer.component.html",
  styleUrls: ["./products-sales-analyzer.component.scss"],
})
export class ProductsSalesAnalyzerComponent implements OnInit, OnChanges {
  showOverlay: boolean;
  displayAppliedFilters;
  appliedFilters;
  detectChangesOnFilters: boolean = false;
  queryParamsSubscription: any;
  dynamic_headers: any[];
  reportName: string = "Product_Sales_Attributes";
  data: any[];
  reportNameWithSpace: any;
  tableDataFetched: boolean;
  selectedTabIndex: number = 0;
  count: any;
  fieldData: any;
  headerData: any;
  visiblePOPUP: boolean;
  subHeadings = subHeadings;
  today = new Date();

  public datePipe = new DatePipe("en-GB");

  //For dimensions tab
  showTableView: boolean = false;
  dimensionsSummaryData: any;
  dimensionsLoaded: boolean;
  dimensionOptionSelected: any = {};
  dimensions: any;
  optionsFilter: FormGroup;
  metrics: any;
  chartData: any;
  tableData: any;
  templateOptions = [
    {
      label: "Product Sales with Attributes",
      code: "product_sales_with_attributes",
    },
    {
      label: "Product Sales by Sales Channel",
      code: "product_sales_by_channel",
    },
    { label: "Sku Sales by Sales Channel", code: "sku_sales_by_channel" },
  ];

  selectedTemplate: string = "product_sales_with_attributes"; // Default value
  constructor(
    private filterServices: FilterUiService,
    private route: ActivatedRoute,
    private listingService: ListingGridService,
    private commonUtilityService: CommonUtilityService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    // this.reportName = this.templateToReportNameMap[localStorage.getItem('selectedTemplate')];
    const savedTemplate = localStorage.getItem("selectedTemplate");
    if (savedTemplate) {
      this.selectedTemplate = savedTemplate;
    } else this.selectedTemplate = this.selectedTemplate;
    if (this.templateToReportNameMap[this.selectedTemplate]) {
      this.reportName = this.templateToReportNameMap[this.selectedTemplate];
    } else {
      // Fallback to default reportName if no valid mapping is found
      this.reportName = "Product_Sales_Attributes";
    }

    let defaultPayload = this.getDefaultDateRanges();
    defaultPayload.dataTemplate = this.selectedTemplate;
    this.reloadPage(defaultPayload);

    this.displayAppliedFilters = {
      DateRange: `${defaultPayload.startDate} - ${defaultPayload.endDate}`,
    };
    this.appliedFilters = defaultPayload;
    this.selectedTabIndex = 0;
    this.optionsFilter = new FormGroup({
      dimension: new FormControl(null),
      metric: new FormControl(null),
    });
    this.optionsFilter.get("dimension").valueChanges.subscribe((value) => {
      this.dimensionOptionSelected.dimension = value;
      this.loadDimensionData();
    });
    this.optionsFilter.get("metric").valueChanges.subscribe((value) => {
      this.dimensionOptionSelected.metric = value;
      this.loadDimensionData();
    });
  }

  setTableView(flag) {
    this.showTableView = flag;
  }
  templateToReportNameMap: { [key: string]: string } = {
    product_sales_with_attributes: "Product_Sales_Attributes",
    product_sales_by_channel: "Product_Sales_Channel",
    sku_sales_by_channel: "SKU_Sales_Channel",
  };

  onTemplateChange(selectedValue: string): void {
    this.selectedTemplate = selectedValue;
    localStorage.setItem("selectedTemplate", selectedValue);

    this.reportName =
      this.templateToReportNameMap[localStorage.getItem("selectedTemplate")];

    // Merge the existing filters (like brand) with the new template filter payload
    const updatedPayload = {
      ...this.appliedFilters, // Include the current filters like brand
      dataTemplate: this.selectedTemplate, // Add the selected template
      startDate: this.appliedFilters.startDate, // Ensure date range is consistent
      endDate: this.appliedFilters.endDate, // Ensure date range is consistent
    };

    // Update the applied filters display for UI if needed
    // this.displayAppliedFilters = {
    //   ...this.displayAppliedFilters,
    //   dataTemplate: this.selectedTemplate,
    // };

    this.reloadPage(updatedPayload);
  }

  loadDimensionData() {
    if (
      this.dimensionOptionSelected.dimension &&
      this.dimensionOptionSelected.metric
    ) {
      this.listingService
        .filterProductSalesSummaryByDimension(
          this.dimensionOptionSelected.dimension,
          this.dimensionOptionSelected.metric,
          this.appliedFilters
        )
        .subscribe(
          (res: any) => {
            this.dimensionsSummaryData = res;
            this.chartData = null;
            let datasets = this.dimensionsSummaryData.datasets;
            let labels = this.dimensionsSummaryData.labels;

            if (datasets.length > 0) {
              let yAxis =
                datasets[0].dataType == "currency"
                  ? {
                      type: "linear",
                      position: "left",
                      title: {
                        display: true,
                        text: datasets[0].label,
                        font: {
                          weight: "bold",
                        },
                      },
                      ticks: {
                        format: {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 0,
                        },
                      },
                      display: true,
                      grid: {
                        display: true,
                        lineWidth: 0.5,
                      },
                    }
                  : {
                      type: "linear",
                      position: "left",
                      title: {
                        display: true,
                        text: datasets[0].label,
                        font: {
                          weight: "bold",
                        },
                      },
                      display: true,
                      grid: {
                        display: true,
                        lineWidth: 0.5,
                      },
                    };
              this.chartData = {
                data: {
                  datasets: [
                    {
                      data: datasets[0].data,
                      label: datasets[0].label,
                      backgroundColor: "#ffa000",
                    },
                    {
                      data: datasets[1].data,
                      label: datasets[1].label,
                      backgroundColor: "#90a3f5",
                      yAxisID: "secondary-y",
                    },
                  ],
                  labels: labels,
                },
                options: {
                  //indexAxis: 'y', used to change to horizontal bar
                  locale: "en-IN",
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      title: {
                        display: true,
                        text: this.dimensionOptionSelected.dimension,
                        font: {
                          weight: "bold",
                        },
                      },
                      type: "category",
                    },
                    y: yAxis,
                    "secondary-y": {
                      type: "linear",
                      min: 0,
                      max: Math.round(Math.max(...datasets[1].data) * 1.25),
                      position: "right",
                      title: {
                        display: true,
                        text: datasets[1].label,
                        font: {
                          weight: "bold",
                        },
                      },
                      display: true,
                      grid: {
                        display: true,
                        lineWidth: 0.5,
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                },
                type: "bar",
              };
              try {
                let numberFormat = new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                });
                let tableItems = [];
                let productCount: number[] = datasets[1].data;
                let metric: number[] = datasets[0].data;

                labels.forEach((value: string, index) => {
                  tableItems.push({
                    identifier: value,
                    metric:
                      datasets[0].dataType == "currency"
                        ? numberFormat.format(metric[index])
                        : metric[index],
                    productCount: productCount[index],
                  });
                });
                this.tableData = {};
                this.tableData.data = tableItems;
                this.tableData.count = labels.length;
                this.tableData.headers = [
                  {
                    field: "identifier",
                    headerText: this.dimensionOptionSelected.dimension,
                    allowFiltering: true,
                    allowSorting: false,
                    textAlign: "left",
                    width: "180",
                  },
                  {
                    field: "metric",
                    headerText: datasets[0].label,
                    allowFiltering: true,
                    allowSorting: true,
                    textAlign: "left",
                    width: "180",
                  },
                  {
                    field: "productCount",
                    headerText: "Product Count",
                    allowFiltering: false,
                    allowSorting: true,
                    textAlign: "left",
                    width: "150",
                  },
                ];
              } catch (e) {
                this.tableData = null;
                console.error(e);
              }
            }
          },
          (error) => {
            this.dimensionsSummaryData = "Not Loaded";
          }
        );
    }
  }

  handlePOPUP(event) {
    this.fieldData = event.fieldData;
    this.headerData = event.header;
    this.visiblePOPUP = true;
  }
  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe(); // Unsubscribe to avoid memory leaks
    }
  }
  tabChanged(i) {
    this.selectedTabIndex = i;
    if (i == 1) {
      this.loadDimensionsTab();
    }
  }
  loadDimensionsTab() {
    if (!this.dimensions && !this.metrics) {
      this.listingService.getVisualizeOptions().subscribe(
        (res: any) => {
          if (res) {
            this.dimensions = res.dimensions;
            this.metrics = res.metrics;
            this.dimensionsLoaded = true;
          }
        },
        (error) => {
          this.dimensionsLoaded = true;
        }
      );
    }
  }
  toggleOverlay() {
    this.showOverlay = !this.showOverlay;
  }
  reloadPage(payload) {
    this.data = [];
    this.dynamic_headers = [];
    this.tableDataFetched = false;
    console.log("reloading page");
    if (this.selectedTabIndex == 0) {
      this.listingService.filterProductSalesSummary(payload).subscribe(
        (res: any) => {
          if (res) {
            this.data = res.items;
            this.count = res.count;
            this.dynamic_headers = JSON.parse(res.headerContent);
            setTimeout(() => {
              this.tableDataFetched = true;
            });
          }
        },
        (error) => {
          this.tableDataFetched = true;
        }
      );
    } else {
      this.loadDimensionData();
    }
  }

  getDefaultDateRanges(): any {
    let payload: any = {};
    let temp = new Date();
    temp.setDate(temp.getDate() - 30);
    payload.startDate = this.datePipe.transform(temp, "yyyy-MM-dd");

    payload.endDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    return payload;
  }
  showFilters() {
    this.showOverlay = true;
  }

  closeFilters(){
    this.showOverlay=false;
  }
  applyFilters(event: any) {
    this.tableDataFetched = false;
    this.showOverlay = false;
    let selectedFilter: any = {};
    console.log("apply filters");
    if (event.filters && Object.keys(event.filters)) {
      selectedFilter.filters = event.filters;
    }
    if (event.DateRange) {
      selectedFilter.DateRange = event.DateRange;
    }
    event.dataTemplate=this.selectedTemplate;
    this.displayAppliedFilters = selectedFilter;
    this.appliedFilters = event;
    Object.keys(event["filters"]).length > 0 || event.startDate || event.endDate
      ? this.listingService.filterProductSalesSummary(event).subscribe(
          (res) => {
            if (res) {
              this.data = res["items"];
              this.dynamic_headers = JSON.parse(res["headerContent"]);
              this.count = res["count"];
              this.loadDimensionData();
              setTimeout(() => {
                this.tableDataFetched = true;
              });
            }
          },
          (error) => {
            this.tableDataFetched = true;
          }
        )
      : this.reloadPage({});
  }
}
