import {Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-supplier-performance-organism',
  templateUrl: './supplier-performance-organism.component.html',
  styleUrls: ['./supplier-performance-organism.component.scss']
})
export class SupplierPerformanceOrganismComponent implements OnInit {
  @ViewChild('lineChartCanvas') private chartCanvas!: ElementRef;
  displayedColumns: string[] = ['product', 'type', 'status', 'oct24', 'nov24', 'dec24', 'jan25', 'feb25', 'mar25'];
  dataSource: any[] = [
    { product: 'JP3857PK', type: 'Clutch Housing', status: 'Planned', oct24: 350, nov24: 400, dec24: 425, jan25: 375, feb25: 340, mar25: 450 },
    { product: 'JP3857PK', type: 'Clutch Housing', status: 'Actual', oct24: 326, nov24: 375, dec24: 340, jan25: 0, feb25: 0, mar25: 0 },
    { product: 'DR1750WH', type: 'Gear Case Cover', status: 'Planned', oct24: 400, nov24: 460, dec24: 490, jan25: 430, feb25: 390, mar25: 520 },
    { product: 'DR1750WH', type: 'Gear Case Cover', status: 'Actual', oct24: 390, nov24: 393, dec24: 473, jan25: 0, feb25: 0, mar25: 0 },
  ];
  isLoading: boolean = true;
  chartData: any;
  chart: any
  config: any

  ngOnInit(): void {
    // this.createLineChart();
    const response = {
      labels: ['Jul-24', 'Aug-24', 'Sep-24', 'Oct-24', 'Nov-24', 'Dec-24'],
      data: [93, 95, 94, 95, 89, 89]
    };
    const data = {
      labels: response.labels,
      datasets: [{
        label: 'Supplier Performance',
        data: response.data,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };
    this.config = {
      type: "line",
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "top"
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
              font: {
                weight: 'bold'
              }
            }
          },
          y: {
            title: {
              display: true,
              text: 'Percentage (%)',
              font: {
                weight: 'bold'
              }
            },
            min: 50,
            max: 100,
            ticks: {
              callback: function (value) {
                return value + '%';  // Adding percentage sign to each Y tick
              }
            }
          }
        }
      }
    };
  }

}
