<div class="bom-container" *ngIf="isLoading">
  <ng-container *ngFor="let number of [0, 1, 2, 3, 4]">
    <div class="holder-card mat-elevation-z4">
      <div style="font-weight: lighter; width: 60%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 80%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 80%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 95%; height: 1em" class="flowsmart-placeholder"></div>
    </div>
  </ng-container>
</div>
<app-syncfusion-grid [reportName]="'BPR'" [dynamic_headers]="dynamic_headers" [count]="count" [data]="data"
  *ngIf="tableDataFetched"></app-syncfusion-grid>
