<div class="bom-container" *ngIf="isLoading">
  <ng-container *ngFor="let number of [0, 1, 2, 3, 4]">
    <div class="holder-card mat-elevation-z4">
      <div style="font-weight: lighter; width: 60%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 80%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 80%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 95%; height: 1em" class="flowsmart-placeholder"></div>
    </div>
  </ng-container>
</div>
<div *ngIf="dataList">
  <app-historical-reports-molecules [reportName]="reportName" [dynamic_headers]="dynamicColumns" [gridData]="dataList"
    [isGrouping]="true"></app-historical-reports-molecules>
</div>