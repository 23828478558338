import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettingService } from 'src/app/services/app-setting.service';

@Component({
  selector: 'app-dynamic-custom-object-organism',
  templateUrl: './dynamic-custom-object-organism.component.html',
  styleUrls: ['./dynamic-custom-object-organism.component.scss']
})
export class DynamicCustomObjectOrganismComponent implements OnInit {
  customEntityObj: any;
  customObj: any;
  settings: any;
  type:any
  displayType:any;

  constructor(private settingService: AppSettingService,private route: ActivatedRoute) { }
  ngOnInit() {
    this.type = this.route.snapshot.paramMap.get('type');
    this.settings = this.settingService?.getSettings() || {}; 
    this.customEntityObj = this.settings['custom_entity'] || {};
    this.customObj = this.customEntityObj[this.type] || null;
    this.displayType =  this.customObj['type'] || 'list';
  }

}
