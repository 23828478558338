import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-content-help-text',
  templateUrl: './header-content-help-text.component.html',
  styleUrls: ['./header-content-help-text.component.scss']
})
export class HeaderContentHelpTextComponent {
  @Input() Heading;
  @Input() helpText;

  currentClass: string = '';
  getHeadingStyle() {
    this.currentClass = '';
    if (!this.Heading?.includes("fetch")) {
      this.currentClass = 'fade-in';
      return {};
    } else {
      return {
        'font-style': 'italic',
        'color': 'grey',
        'font-weight': '200'
      };
    }
  }

}
